import React, {useEffect, useState} from "react";
import {getToken} from "../../store/actions/auth";
import {GET_PLAYER} from "../../utils/apis";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {setPlayer} from "../../store/actions/track";
import {getPageOptions} from "../../store/actions/project";

export const Players = () => {
  const dispatch = useAppDispatch();
  const options = useAppSelector((state) => state.project.options);
  const [playerMedia, setPlayerMedia] = useState('');
  const [isPlay, setPlay] = useState(false);
  const player = useAppSelector((state) => state.track.player);
  const [theme, setTheme] = useState(localStorage.getItem('playButton'))
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [offset, setOffset] = useState(0);

  const handleScroll = () => {
    setOffset(window.scrollY)
    setTheme(localStorage.getItem('playButton'))
  }

  // eslint-disable-next-line
  useEffect(()=>{
    if(!document.querySelectorAll('.home-wrapper')[0]) {
      setTheme('light')
    }
  })

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line
  }, [])

  const playerHandler = async () => {
    let clickEvent = new MouseEvent("click", {
      "view": window,
      "bubbles": true,
      "cancelable": true
    });
    if(!isPlay) {
      if(document.querySelectorAll('.ochre-js-play')[0]) {
        document.querySelectorAll('.ochre-js-play')[0].dispatchEvent(clickEvent);
      }
      else {
        // @ts-ignore
        player.play(playerMedia)
      }
      setPlay(true)
    }
    else{
      // @ts-ignore
      document.querySelectorAll('.ochre-js-pause')[0].dispatchEvent(clickEvent);
      // player.pause();
      setPlay(false)
    }
  }
// eslint-disable-next-line
  useEffect(()=>{
    if(document.querySelectorAll('.ochre-js-pause')[0] && document.querySelectorAll('.ochre-js-play')[0]) {
      document.querySelectorAll('.ochre-js-pause')[0].addEventListener('click', () => {
        setPlay(false)
      })
      document.querySelectorAll('.ochre-js-play')[0].addEventListener('click', () => {
        setPlay(true)
      })
      document.querySelectorAll('.ochre-playlist-toggle.ochre-js-toggle-playlist')[0].addEventListener('click', () => {
        const header = document.createElement('li');
        const trackTitle = document.createElement('h5');
        const artistTitle = document.createElement('h5');
        const releaseTitle = document.createElement('h5');
        const timeTitle = document.createElement('h5');
        header.classList.add('ochre-player-header')
        trackTitle.classList.add('h5')
        trackTitle.textContent = 'Track'
        artistTitle.classList.add('h5')
        artistTitle.textContent = 'Artist'
        releaseTitle.classList.add('h5')
        releaseTitle.textContent = 'Release'
        timeTitle.classList.add('h5')
        timeTitle.textContent = 'Time'
        header.append(trackTitle, artistTitle, releaseTitle, timeTitle)
        if(!document.querySelectorAll('.ochre-player-header')[0]) {
          // @ts-ignore
          document.getElementById('ochre-playlist').prepend(header)
        }
      });
    }
    // @ts-ignore
    if(document.querySelectorAll('.ochre-transport-wrapper.ochre-player-footer')[0] && document.querySelectorAll('.ochre-transport-wrapper.ochre-player-footer')[0].style.display !== 'none') {
      document.body.classList.add('simple-player')
    }
    else{
      document.body.classList.remove('simple-player')
    }
    if(document.querySelectorAll('.ochre-transport-wrapper.ochre-player-footer')[0]) {
      document.querySelectorAll('.ochre-minimize-toggle.ochre-js-toggle-minimized')[0].addEventListener('click', () => {
        document.body.classList.remove('simple-player')
      });
    }
  });

  useEffect(()=>{
    const makeAsync = async () => {
      await dispatch(getPageOptions());
    }
    makeAsync();
    // @ts-ignore
    dispatch(setPlayer(Player.init('#player', {})))
  }, [dispatch])

  useEffect(()=> {
    if (options && options.acf) {
      const makeAsync = async () => {
        const token = await getToken();

        fetch(GET_PLAYER + options.acf.playlist_id + '&type=audio', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          },
        }).then((response) => response.json())
          .then(json => {
            setPlayerMedia(json.results)
          })
      }
      makeAsync();
    }
  }, [dispatch, options])

  return (
    options && options.acf ?
      <button className={theme === 'dark' ? 'p btn-black btn-play' : 'p btn-red btn-play'} id={isPlay ? 'play-button': 'stop-button'} onClick={playerHandler}>{isPlay ? 'Stop' : 'Play'}</button>
      :
      <></>
  )
}