import {Dispatch} from "redux";
import {
    GET_PAGE_ERROR,
    GET_PAGE_LOADING,
    GET_PAGE_OPTIONS_ERROR,
    GET_PAGE_OPTIONS_LOADING,
    GET_PAGE_OPTIONS_SUCCESS,
    GET_PAGE_SUCCESS,
    GET_PROJECTS_ERROR,
    GET_PROJECTS_LOADING,
    GET_PROJECTS_SUCCESS, GET_SINGLE_LANDING_ERROR,
    GET_SINGLE_LANDING_LOADING,
    GET_SINGLE_LANDING_SUCCESS,
    GET_SINGLE_PROJECT_ERROR,
    GET_SINGLE_PROJECT_LOADING,
    GET_SINGLE_PROJECT_SUCCESS,
} from "../../utils/types";
import {GET_CHILD_PAGES, GET_LANDING, GET_OPTIONS_PAGE, GET_PAGE, GET_PROJECTS} from "../../utils/apis";

export const getProjects = () => async (dispatch: Dispatch) => {
    try {
        dispatch({
            type: GET_PROJECTS_LOADING
        });

        fetch(GET_PROJECTS + '?order=asc', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((response) => response.json())
            .then(json => {
                dispatch({
                    type: GET_PROJECTS_SUCCESS,
                    payload: json
                });
            })
    } catch (e) {
        dispatch({
            type: GET_PROJECTS_ERROR
        });
    }
}

export const getPageInfo = (id: number) => async (dispatch: Dispatch) => {
    try {
        dispatch({
            type: GET_PAGE_LOADING
        });

        fetch(GET_PAGE + id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((response) => response.json())
          .then(json => {
              dispatch({
                  type: GET_PAGE_SUCCESS,
                  payload: json
              });
          })
    } catch (e) {
        dispatch({
            type: GET_PAGE_ERROR
        });
    }
}

export const getChildPages = (id: number) => async (dispatch: Dispatch) => {
    try {
        dispatch({
            type: GET_PAGE_LOADING
        });

        fetch(GET_CHILD_PAGES + id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((response) => response.json())
          .then(json => {
              dispatch({
                  type: GET_PAGE_SUCCESS,
                  payload: json
              });
          })
    } catch (e) {
        dispatch({
            type: GET_PAGE_ERROR
        });
    }
}

export const getPageOptions = () => async (dispatch: Dispatch) => {
    try {
        dispatch({
            type: GET_PAGE_OPTIONS_LOADING
        });

        fetch(GET_OPTIONS_PAGE, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((response) => response.json())
          .then(json => {
              dispatch({
                  type: GET_PAGE_OPTIONS_SUCCESS,
                  payload: json
              });
          })
    } catch (e) {
        dispatch({
            type: GET_PAGE_OPTIONS_ERROR
        });
    }
}

export const getSingleProject = (id:string) => async (dispatch: Dispatch) => {
    try {
        dispatch({
            type: GET_SINGLE_PROJECT_LOADING
        });
        fetch(GET_PROJECTS + id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        }).then((response) => response.json())
            .then(json => {
                dispatch({
                    type: GET_SINGLE_PROJECT_SUCCESS,
                    payload: json
                });
            })
    } catch (e) {
        dispatch({
            type: GET_SINGLE_PROJECT_ERROR
        });
    }
}

export const getSingleLanding = (slug:string) => async (dispatch: Dispatch) => {
    try {
        dispatch({
            type: GET_SINGLE_LANDING_LOADING
        });
        fetch(GET_LANDING + slug, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        }).then((response) => response.json())
          .then(json => {
              dispatch({
                  type: GET_SINGLE_LANDING_SUCCESS,
                  payload: json[0]
              });
          })
    } catch (e) {
        dispatch({
            type: GET_SINGLE_LANDING_ERROR
        });
    }
}