import React, {useEffect, useState} from "react";
import {Header} from "../../components/Header";
import './style.css';
import {Footer} from "../../components/Footer";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {useParams} from 'react-router';
import {getReleaseInfo, getTracksByRelease, getTracksFormat} from "../../store/actions/track";
import {Link} from "react-router-dom";
import {getToken} from "../../store/actions/auth";
import {GET_MEDIAS_TRACK} from "../../utils/apis";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import 'swiper/swiper.min.css'
import 'swiper/swiper-bundle.min.css'
import { FreeMode, Navigation } from "swiper";
import {changeColorBackgroundMeta, closePlayer, convertDate, convertSeconds} from "../../utils/helpers";
import {IframeVideo} from "../../components/ACF/iframe-video";
// import {API_HOST} from "../../utils/constants";

export const ReleaseScreen = () => {

    // @ts-ignore
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const release = useAppSelector((state) => state.track.shopTrack);
    const product = useAppSelector((state) => state.track.shopProduct);
    const links = useAppSelector((state) => state.track.shopLinks);
    const tracks = useAppSelector((state) => state.track.releaseTracks);
    const tracksFormat = useAppSelector((state) => state.track.tracksFormat);
    const images = useAppSelector((state) => state.track.images);
    const videos = useAppSelector((state) => state.track.videos);
    const isLoading = useAppSelector((state) => state.track.isLoadingShop);
    const isLoadingTracks = useAppSelector((state) => state.track.isLoadingReleaseTracks);
    const player = useAppSelector((state) => state.track.player);
    const [showMore, setShowMore] = useState(false);
    const [isLoadingCustom, setLoadingCustom] = useState(true)
    const [isModal, setModal] = useState(false);
    const [activeSlide, setActiveSlide] = useState(0);
    const [modalSwiper, setModalSwiper] = useState(null);
    const [scrolled, setScrolled] = useState(false);
    const [itemCart, setItemCart] = useState(0);
    // const [bundleItems, setBundleItems] = useState([]);

    useEffect(() => {
        const makeAsync = async () => {
            await dispatch(getReleaseInfo(id));
            await dispatch(getTracksByRelease(id));
            await dispatch(getTracksFormat(id));
        };
        makeAsync();
    }, [dispatch, id]);

    useEffect(()=>{
        if(!isLoading){
            setTimeout(()=>{
                setLoadingCustom(false)
            },1500)
        }
    }, [isLoading])

    useEffect(()=>{
        let productsId;
        // @ts-ignore
        window.buy.cart('.ochre-cart-container');

        // if(product.map((item:any)=>item.distribution === 'physical')) {
        // }
        // else {
            if (Array.isArray(product)) {
                productsId = product.map((item: any) => {
                    return item.id
                })
            } else {
                productsId = product.id
            }
            // @ts-ignore
            window.buy.products('.product-list', {style: 'mini'}).get(productsId);
        // }
        if(document.querySelectorAll('.ochre-cart-count')[0]) {
            if (document.querySelectorAll('.ochre-cart-count')[0].textContent === '0') {
                document.querySelectorAll('.ochre-cart-container')[0].classList.add('hide')
            } else {
                document.querySelectorAll('.ochre-cart-container')[0].classList.remove('hide')
            }
        }
    }, [product])

    useEffect(()=>{
        document.body.classList.add('orange-player')
        document.body.classList.remove('scroll-down')
    }, [])

    const externalOpen = (id: any, code: string) => {
        if(code === 'apple') {
            window.open('https://geo.music.apple.com/us/album/' + id, '_blank')
        }
        else if(code === 'spotify') {
            window.open('https://open.spotify.com/album/' + id, '_blank')
        }
    }

    const playerHandler = async (id:string) => {
        const token = await getToken();
        fetch(GET_MEDIAS_TRACK + id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ token
            },
        }).then((response) => response.json())
          .then(json => {
              player.play(json.results)
          })
    }

    const addToBasket = async (ids: number) => {
        let arr: any[] = [];

        tracksFormat.map((item: any) => {
            if(item.group.id === ids){
                arr.push(item)
            }
            return arr;
        })

        const productsId = arr.map((item: any) => {return item.id})
        if(!document.querySelectorAll('.release-single-cart-'+ids)[0].firstChild) {
            // @ts-ignore
            buy.products('.release-single-cart-' + ids, {style: 'mini'}).get(productsId);
        }
        else{
            while (document.querySelectorAll('.release-single-cart-'+ids)[0].firstChild) {
                // @ts-ignore
                document.querySelectorAll('.release-single-cart-'+ids)[0].firstChild.remove()
            }
        }
    }

    const modalHandler = (index: number) => {
        setModal(true)
        setActiveSlide(index)
    }

    const changeCursor = (e: any) => {
        const img = document.querySelectorAll(".modal-slider")[0];
        const {width} = img.getBoundingClientRect();
        const halfImgWidth = width / 2;

        //@ts-ignore
        const xPos = e.pageX - img.offsetLeft;
        img.classList.remove("cursor-prev", "cursor-next");
        if (xPos > halfImgWidth) {
            img.classList.add("cursor-next");
        } else {
            img.classList.add("cursor-prev");
        }
    }

    const changeSlide = () => {
        if(document.querySelectorAll('.cursor-next')[0]) {
            // @ts-ignore
            modalSwiper.slideNext()
        }
        else{
            // @ts-ignore
            modalSwiper.slidePrev()
        }
    }

    useEffect(()=>{
        window.addEventListener("click", handleClick);
        return () => {
            window.removeEventListener("click", handleClick);
        };
    })

    const handleClick = (e:any) => {
        setTimeout(()=>{
            if(document.querySelectorAll('.ochre-cart-count')[0].textContent === '0'){
                document.querySelectorAll('.ochre-cart-container')[0].classList.add('hide')
            }
            else{
                document.querySelectorAll('.ochre-cart-container')[0].classList.remove('hide')
            }
        },1000)
        if(document.querySelectorAll('.ochre-cart')[0]){
            if(document.querySelector('.ochre-cart-count')) {
                const target = document.querySelector('.ochre-cart-count')
                const observer = new MutationObserver(function (mutations) {
                    // @ts-ignore
                    // eslint-disable-next-line eqeqeq
                    if ((!scrolled && target.innerText == 1) || target.innerText == Number(itemCart) + 1) {
                        window.scrollTo({top: 0, behavior: 'smooth'});
                        document.querySelectorAll('.ochre-cart-container')[0].classList.add('shown');
                        document.querySelectorAll('.ochre-cart-container')[0].classList.remove('hide');
                        setScrolled(true)
                    }
                });

                // @ts-ignore
                observer.observe(target, {
                    attributes: true,
                    childList: true,
                    characterData: true
                });
            }
        }
        if(e.target.classList.value === 'ochre-buy-button'){
            e.target.classList.add('added')
        }
    }

    useEffect(()=>{
        closePlayer();
        changeColorBackgroundMeta('#393337');
        setTimeout(()=>{
            // @ts-ignore
            if(document.querySelector('.ochre-cart-count')) {
                // @ts-ignore
                setItemCart(document.querySelector('.ochre-cart-count').innerText)
            }
        },3000)
    },[])
    //
    // const bundleHandler = async (id: number) => {
    //     const token = await getToken();
    //     fetch(API_HOST + `/retail/products/${id}/bundle-items`, {
    //         method: 'GET',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': 'Bearer ' + token
    //         },
    //     }).then((response) => response.json())
    //       .then(resp => {
    //           // let ids = resp.results.map((r: any) => {
    //           //     return r.id
    //           // })
    //           // setBundleItems(resp.results)
    //       })
    // }

    return (
        <main>
            <section className="single-release-section">
                <div className={isLoadingCustom ? 'custom-loader' : 'custom-loader hide'}>
                    <p>loading...</p>
                </div>
                <Header close/>
                {!isLoading && release && release.title &&
                    <div className='shop-item-wrapper'>
                        <div className="left-side">
                            <div className="main-slider-wrapper">
                                <Swiper
                                  modules={[FreeMode, Navigation]}
                                  className="main-slider"
                                  onActiveIndexChange={(swiperCore) => setActiveSlide(swiperCore.activeIndex)}
                                >
                                    <SwiperSlide key={100} onClick={()=>modalHandler(0)}>
                                        <img src={release.images[4].url} alt=""/>
                                    </SwiperSlide>
                                    {images.map((item: any, index: number) => (
                                      <SwiperSlide key={index} onClick={()=>modalHandler(index + 1)}>
                                          <img src={item.images[4].url} alt="" key={index}/>
                                      </SwiperSlide>
                                    ))}
                                </Swiper>
                                <div className="total-slides">{activeSlide + 1}/{images.length+1}</div>
                            </div>
                        </div>
                        <div className="right-side">
                            <h5 className="h5">{release.title}</h5>
                            <p className="p">{release.artist && release.artist.name}</p>
                            <p className="p">{release.catNumber}</p>
                            <p className="p">{release.releaseDate && convertDate(release.releaseDate)}</p>
                            <div className="product-list"/>
                            {/*<div className='bundle-wrapper'>*/}
                            {/*    {product.filter((item: any) => item.format === 'Bundle').map((i: any) => (*/}
                            {/*      <div className='bundle-item' onClick={()=>bundleHandler(i.id)}>{i.shortDescription}</div>*/}
                            {/*    ))}*/}
                            {/*    {bundleItems.length > 0 && bundleItems.map((item:any)=>(*/}
                            {/*      <div>{item.shortDescription}</div>*/}
                            {/*    ))}*/}
                            {/*</div>*/}
                            {links && links.length > 0 && (
                              <div className="product-links">
                                  {links.filter((item:any) => item.format === "external_id" && (item.service.code === 'apple' || item.service.code === 'spotify')).map((item:any, index: number) => (
                                    <button className="btn-external" onClick={()=>externalOpen(item.asset, item.service.code)} key={index}>
                                        {item.service.name}
                                    </button>
                                  ))}
                              </div>
                            )}
                            {release.artist &&
                            <Link to={'/artist/'+release.artist.id} className="p producer">Producer {release.artist.name}</Link>
                            }
                            {release.description ?
                              <p className="p content">
                                  {showMore ? release.description : `${release.description.substring(0, 500)}`}
                                  {release.description.length >= 500 && <button className="btn-read" onClick={() => setShowMore(!showMore)}>{!showMore ? 'READ MORE' : 'SHOW LESS'}</button>}
                              </p>
                              : release.group && release.group.description ?
                                <p className="p content">
                                    {showMore ? release.group.description : `${release.group.description.substring(0, 500)}`}
                                    {release.group.description.length >= 500 && <button className="btn-read" onClick={() => setShowMore(!showMore)}>{!showMore ? 'READ MORE' : 'SHOW LESS'}</button>}
                                </p>
                                :
                                <></>
                            }
                        </div>
                    </div>
                }
                {!isLoadingTracks && tracks.length > 0 &&
                  <div className="release-tracks">
                      <p className="p">Digital Track List</p>
                      {tracks.map((item: any, index: number) => (
                        <div className="release-single" key={index}>
                            <p className="title-track" onClick={()=>playerHandler(item.id)}>{item.title}</p>
                            <div className="release-single-right">
                                <p className="time-track">{convertSeconds(item.length)}</p>
                                <button className="btn-buy" onClick={()=>addToBasket(item.id)}>{'BUY'}</button>
                            </div>
                            <div className={"release-single-cart-"+item.id}/>
                        </div>
                      ))}
                  </div>
                }
                {videos && videos.length > 0 &&
                  videos.map((video:any) => (
                    <IframeVideo video={video} key={video.id}/>
                  ))
                }
                {!isLoading && release.title && isModal &&
                <div className={isModal ? "modal-wrapper active" : "modal-wrapper"}>
                    <button onClick={() => {setModal(false); setActiveSlide(0)}} className="p orange modal-close">close</button>
                    <Swiper
                      className="modal-slider"
                      initialSlide={activeSlide}
                      //@ts-ignore
                      onInit={setModalSwiper}
                      onActiveIndexChange={(swiperCore) => setActiveSlide(swiperCore.activeIndex)}
                    >
                        <SwiperSlide key={100} onMouseMove={changeCursor} onClick={changeSlide}>
                            <img src={release.images[4].url} alt=""/>
                        </SwiperSlide>
                        {images
                          .sort((a: any, b: any) => a.id - b.id)
                          .map((item: any, index: number) => (
                          <SwiperSlide key={index} onMouseMove={changeCursor} onClick={changeSlide}>
                              <img src={item.images[4].url} alt="" key={index}/>
                          </SwiperSlide>
                        ))}
                    </Swiper>
                    <div className="total-slides">{activeSlide + 1}/{images.length+1}</div>
                </div>
                }
            </section>
            <Footer transparent={true}/>
        </main>
    )
}