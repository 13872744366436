import React, {useState} from "react";
import YouTube from 'react-youtube';

export const IframeVideo = ({video}:{video: any}) => {

  const [player, setPlayer] = useState<any>();
  // const [videoUrl, setVideoUrl] = useState(video.url);
  const [isPlay, togglePlay] = useState(false)
  const [isMute, toggleMute] = useState(false)
  const [isFull, toggleFull] = useState(false)

  // useEffect(() => {
  //   if(videoUrl && videoUrl.includes('youtube')) {
  //     const id = videoUrl.split("watch/")[1];
  //     if(id) {
  //       setVideoUrl("http://www.youtube.com/embed/" + id);
  //       const tag = document.createElement("script");
  //       tag.src = "https://www.youtube.com/iframe_api";
  //       tag.setAttribute("onload", "onYouTubeIframeReady()");
  //       const firstScriptTag = document.getElementsByTagName("script")[0];
  //       // @ts-ignore
  //       firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  //       // @ts-ignore
  //       window.onYouTubeIframeReady = function () {
  //           setPlayer(
  //             // @ts-ignore
  //             new YT.Player(`player${video.id}`, {
  //               videoId: id,
  //               autoplay: false,
  //               // events: { 'onStateChange': YTStateChange }
  //             })
  //           );
  //       };
  //     }
  //   }
  // }, [player, video.id, videoUrl]);

  const playHandler = () => {
    if(isPlay) {
      player.pauseVideo();
    }
    else{
      player.playVideo();
    }
    togglePlay(!isPlay)
  }

  const muteHandler = () => {
    if(isMute){
      player.unMute();
    }
    else{
      player.mute();
    }
    toggleMute(!isMute)
  }

  const fullHandler = (event:any) => {
    event.preventDefault();
    toggleFull(!isFull)
    const el:any = document.getElementById(`player${video.id}`);
    if (el.requestFullscreen) {
      el.requestFullscreen();
    } else if (el.msRequestFullscreen) {
      el.msRequestFullscreen();
    } else if (el.mozRequestFullScreen) {
      el.mozRequestFullScreen();
    } else if (el.webkitRequestFullscreen) {
      el.webkitRequestFullscreen();
    }
  }

  return (
    <>
      <div className="iframe-container" key={video.id}>
        {/*<div id={`player${video.id}`} className='iframe'/>*/}
        <YouTube
          videoId={video.asset}
          className='iframe' id={`player${video.id}`}
          onReady={(event)=>setPlayer(event.target)}
          onPlay={()=>togglePlay(true)}
          onPause={()=>togglePlay(false)}
        />
      </div>
      <div className={'video-buttons'}>
          <button className='small-btn' onClick={playHandler}>{isPlay ? 'PAUSE' : 'PLAY'}</button>
          <button className='small-btn' onClick={muteHandler}>{isMute ? 'UNMUTE' : 'MUTE'}</button>
          <button className='small-btn' onClick={fullHandler}>FULLSCREEN</button>
      </div>
    </>
  )
}