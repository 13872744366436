export const GET_RELEASES_SUCCESS = 'GET_RELEASES_SUCCESS'
export const GET_RELEASES_ERROR = 'GET_RELEASES_ERROR'
export const GET_RELEASES_LOADING = 'GET_RELEASES_LOADING'

export const GET_SINGLE_RELEASE_SUCCESS = 'GET_SINGLE_RELEASE_SUCCESS'
export const GET_SINGLE_RELEASE_ERROR = 'GET_SINGLE_RELEASE_ERROR'
export const GET_SINGLE_RELEASE_LOADING = 'GET_SINGLE_RELEASE_LOADING'

export const GET_ARTISTS_SUCCESS = 'GET_ARTISTS_SUCCESS'
export const GET_ARTISTS_ERROR = 'GET_ARTISTS_ERROR'
export const GET_ARTISTS_LOADING = 'GET_ARTISTS_LOADING'

export const GET_SINGLE_ARTIST_SUCCESS = 'GET_SINGLE_ARTIST_SUCCESS'
export const GET_SINGLE_ARTIST_ERROR = 'GET_SINGLE_ARTIST_ERROR'
export const GET_SINGLE_ARTIST_LOADING = 'GET_SINGLE_ARTIST_LOADING'

export const GET_TRACKS_ARTIST_SUCCESS = 'GET_TRACKS_ARTIST_SUCCESS'
export const GET_TRACKS_ARTIST_ERROR = 'GET_TRACKS_ARTIST_ERROR'
export const GET_TRACKS_ARTIST_LOADING = 'GET_TRACKS_ARTIST_LOADING'

export const GET_CONTENT_ARTIST_SUCCESS = 'GET_CONTENT_ARTIST_SUCCESS'
export const GET_CONTENT_ARTIST_ERROR = 'GET_CONTENT_ARTIST_ERROR'
export const GET_CONTENT_ARTIST_LOADING = 'GET_CONTENT_ARTIST_LOADING'

export const PLAYER_SUCCESS = 'PLAYER_SUCCESS'

export const GET_RELEASE_INFO_SUCCESS = 'GET_RELEASE_INFO_SUCCESS'
export const GET_RELEASE_INFO_ERROR = 'GET_RELEASE_INFO_ERROR'
export const GET_RELEASE_INFO_LOADING = 'GET_RELEASE_INFO_LOADING'

export const GET_TRACKS_RELEASE_SUCCESS = 'GET_TRACKS_RELEASE_SUCCESS'
export const GET_TRACKS_RELEASE_ERROR = 'GET_TRACKS_RELEASE_ERROR'
export const GET_TRACKS_RELEASE_LOADING = 'GET_TRACKS_RELEASE_LOADING'

export const GET_TRACKS_FORMAT_SUCCESS = 'GET_TRACKS_FORMAT_SUCCESS'
export const GET_TRACKS_FORMAT_ERROR = 'GET_TRACKS_FORMAT_ERROR'
export const GET_TRACKS_FORMAT_LOADING = 'GET_TRACKS_FORMAT_LOADING'

export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS'
export const GET_PROJECTS_ERROR = 'GET_PROJECTS_ERROR'
export const GET_PROJECTS_LOADING = 'GET_PROJECTS_LOADING'

export const GET_PAGE_SUCCESS = 'GET_PAGE_SUCCESS'
export const GET_PAGE_ERROR = 'GET_PAGE_ERROR'
export const GET_PAGE_LOADING = 'GET_PAGE_LOADING'

export const GET_PAGE_OPTIONS_SUCCESS = 'GET_PAGE_OPTIONS_SUCCESS'
export const GET_PAGE_OPTIONS_ERROR = 'GET_PAGE_OPTIONS_ERROR'
export const GET_PAGE_OPTIONS_LOADING = 'GET_PAGE_OPTIONS_LOADING'

export const GET_SINGLE_PROJECT_SUCCESS = 'GET_SINGLE_PROJECT_SUCCESS'
export const GET_SINGLE_PROJECT_ERROR = 'GET_SINGLE_PROJECT_ERROR'
export const GET_SINGLE_PROJECT_LOADING = 'GET_SINGLE_PROJECT_LOADING'

export const GET_LANDINGS_SUCCESS = 'GET_LANDINGS_SUCCESS'
export const GET_LANDINGS_ERROR = 'GET_LANDINGS_ERROR'
export const GET_LANDINGS_LOADING = 'GET_LANDINGS_LOADING'

export const GET_SINGLE_LANDING_SUCCESS = 'GET_SINGLE_LANDING_SUCCESS'
export const GET_SINGLE_LANDING_ERROR = 'GET_SINGLE_LANDING_ERROR'
export const GET_SINGLE_LANDING_LOADING = 'GET_SINGLE_LANDING_LOADING'

export const IS_FIRST_RENDER = 'IS_FIRST_RENDER'