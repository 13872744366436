import React from "react";

export const SpotifyBlock = ({layout}:{layout:any}) => {
  return (
    <>
      <div className="sync">
        <h5 className="h5">{layout.title}</h5>
        <p className="p">{layout.text}</p>
        <a href={`mailto:${layout.email}`}>{layout.email}</a>
      </div>
      <div className="spotify-block">
        <p className="p music-title">{layout.spotify_title}</p>
        <iframe
          src={layout.spotify_iframe}
          width="100%" height="380" frameBorder="0" title="spotify" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        />
        <p className="p full-catalog" dangerouslySetInnerHTML={{__html: layout.text_under_spotify}}/>

      </div>
    </>
  )
}