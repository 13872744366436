import React from "react";
import './style.css';

export const Button = (props:any) => {

    return (
        <button
            className={props.theme === 'dark' ? "p btn-black" : props.theme === 'medium' ? "p btn-medium" : "p btn-red"}
            style={props.styles ? {zIndex: 11} : {}}
            onClick={props.open}
        >
            {props.text}
        </button>
    )
}