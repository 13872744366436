import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {Button} from "../Button";
import './style.css';
import {getProjects} from "../../store/actions/project";
import {useAppDispatch, useAppSelector} from "../../store/hooks";

export const Header = (props:any) => {

    const dispatch = useAppDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenSubmenu, setIsOpenSubmenu] = useState(false);
    const [numberSubmenu, setNumberSubmenu] = useState(0);
    const projects = useAppSelector((state) => state.project.projects);
    const history = useHistory();
    const body = document.body;
    const scrollUp = "scroll-up";
    const scrollDown = "scroll-down";
    let lastScroll = 0;

    useEffect(()=>{
        if(!props.open){
            setIsOpen(props.open)
        }
    }, [props])

    useEffect(()=>{
        if(isOpen){
            document.body.style.overflow = 'hidden'
        }
        else{
            document.body.style.overflow = 'unset'
        }
    },[isOpen])

    useEffect(() => {
        const makeAsync = async () => {
            await dispatch(getProjects());
        };
        makeAsync();
    },[dispatch]);

    useEffect(() => {
        window.addEventListener("scroll", headerHandler);
        return () => {
            window.removeEventListener("scroll", headerHandler);
        };
        // eslint-disable-next-line
    }, [])

    const menuHandler = () => {
        setIsOpen(!isOpen)
    }

    const submenuHandler = (id:number) => {
        if(id !==numberSubmenu){
            setIsOpenSubmenu(true)
        }
        else {
            setIsOpenSubmenu(!isOpenSubmenu)
        }
        setNumberSubmenu(id)
    }

    const headerHandler = () => {
        const currentScroll = window.pageYOffset;
        if (currentScroll <= 0) {
            body.classList.remove(scrollUp);
            return;
        }

        if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
            // down
            body.classList.remove(scrollUp);
            body.classList.add(scrollDown);
        } else if (
          currentScroll < lastScroll &&
          body.classList.contains(scrollDown)
        ) {
            // up
            body.classList.remove(scrollDown);
            body.classList.add(scrollUp);
        }
        lastScroll = currentScroll;
    }

    const closeModal = () => {
        setIsOpen(false)
    }

    return (
        <>
            <div className={props.show ? "header hide" : "header"}  style={isOpen ? {zIndex: 4}: {zIndex: 2}}>
                {props.hidelogo ?
                  <div className='hidden-logo'/>
                  :
                   props.close ?
                     <button onClick={history.goBack} className={props.color ? "logo-link dark" : "logo-link"} style={isOpen ? {opacity: 0}: {}}>
                         <p className="p logo-img">close</p>
                     </button>
                     :
                     <Link to="/?p=1" className={props.color ? "logo-link dark" : "logo-link"} style={isOpen ? {opacity: 0}: {}}>
                         <p className="p logo-img">planisphere</p>
                     </Link>
                }
                <Button text={isOpen ? 'Close' : 'Menu'} open={menuHandler} theme={props.color && !isOpen ? 'dark' : ''} styles={isOpen}/>
                <div className='hidden-logo'/>
                <div className={isOpen ? "popup-menu opened" : "popup-menu"}>
                    <ul className="main-menu">
                        <li>
                            <Link to="/about" onClick={closeModal}>Company</Link>
                        </li>
                        <li>
                            <Link to="/services" onClick={closeModal}>Services</Link>
                        </li>
                        <li>
                            <button onClick={()=>submenuHandler(3)} className={isOpenSubmenu && numberSubmenu === 3 ? 'active' : ''}>Projects</button>
                            {projects && projects.length > 0 &&
                              <ul className="submenu">
                                  {projects.map((project: any) => (
                                    <li key={project.id}>
                                        <Link to={`/project/${project.id}`}
                                              onClick={closeModal}>{project.title.rendered}</Link>
                                    </li>
                                  ))}
                              </ul>
                            }
                        </li>
                        {/*<li>*/}
                        {/*    <button onClick={()=>submenuHandler(2)} className={isOpenSubmenu && numberSubmenu === 2 ? 'active' : ''}>Editorial</button>*/}
                        {/*    <ul className="submenu">*/}
                        {/*        <li>*/}
                        {/*            <Link to="/publishing" onClick={closeModal}>Publishing</Link>*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            <Link to="/artists" onClick={closeModal}>Artists</Link>*/}
                        {/*        </li>*/}
                        {/*        /!*<li style={{width: 'max-content'}}>*!/*/}
                        {/*        /!*    <Link to="/label-services" onClick={closeModal}>Label Services</Link>*!/*/}
                        {/*        /!*</li>*!/*/}
                        {/*        <li>*/}
                        {/*            <Link to="/catalog" onClick={closeModal}>Catalog</Link>*/}
                        {/*        </li>*/}
                        {/*    </ul>*/}
                        {/*</li>*/}

                        <li>
                            <Link to="/shop" onClick={closeModal}>Shop</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}