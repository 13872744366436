import React, {useEffect, useState} from "react";
import {Header} from "../../components/Header";
import '../Impressum/style.css';
import {Footer} from "../../components/Footer";
import {getPageInfo} from "../../store/actions/project";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {changeColorBackgroundMeta, closePlayer} from "../../utils/helpers";
import {Helmet} from "react-helmet";

export const PrivacyScreen = () => {

    const dispatch = useAppDispatch();
    const page = useAppSelector((state) => state.project.page);
    const isLoading = useAppSelector((state) => state.project.isLoadingPage);
    const [isLoadingCustom, setLoadingCustom] = useState(true)

    useEffect(()=>{
        const makeAsync = async () => {
            await dispatch(getPageInfo(241));
        };
        makeAsync();
    }, [dispatch])

    useEffect(()=>{
        closePlayer();
        document.body.classList.add('orange-player')
        document.body.classList.remove('scroll-down')
        changeColorBackgroundMeta('#393337');
    }, [])

    useEffect(()=>{
        if(!isLoading){
            setTimeout(()=>{
                setLoadingCustom(false)
            },500)
        }
    }, [isLoading])

    return (
        <main>
            {!isLoading && Object.keys(page).length !== 0 && page.id === 141 &&
              <Helmet>
                  <title>{page.acf.meta_title}</title>
                  <meta name="description" content={page.acf.meta_description} />
                  <meta property="og:image" content={page.acf.meta_image} />
                  <meta property="og:title" content={page.acf.meta_title} />
                  <meta property="og:description" content={page.acf.meta_description} />
              </Helmet>
            }
            <div className={isLoadingCustom ? 'custom-loader' : 'custom-loader hide'}>
                <p>loading...</p>
            </div>
            <section className="privacy">
                <Header/>
                {!isLoading && Object.keys(page).length !== 0 && page.id === 241 ?
                  <div className="container">
                      <p className='p'>{page.title.rendered}</p>
                      <div className="privacy-content" dangerouslySetInnerHTML={{__html: page.content.rendered}}/>
                  </div>
                  :
                  <></>
                }
            </section>
            <Footer transparent={true}/>
        </main>
    )
}