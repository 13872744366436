import React from 'react';
import {hydrate, render} from 'react-dom';
import { store } from './app/store/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import {Routes} from "./app/utils/Routes";
import './index.css'
import {Players} from "./app/components/Players";

const rootElement = document.getElementById("root");
if (rootElement!.hasChildNodes()) {
  hydrate(<Provider store={store}>
    <div id="player"/>
    <Players/>
    <Routes/>
  </Provider>, rootElement);
} else {
  render(<Provider store={store}>
    <div id="player"/>
    <Players/>
    <Routes/>
  </Provider>, rootElement);
}

serviceWorker.unregister();
