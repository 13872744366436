import React from "react";
import './style.css';
// import {PAYMENT_HOST} from "../../utils/constants";
import {Link} from "react-router-dom";

export const Track = (props:any) => {
    return (
        <div className="single-track">
                <div className="single-track-image">
                  {props.isShop ?
                    <Link to={'/release/' + props.track.id}>
                      <img src={props.track.images[5].url} alt=""/>
                    </Link>
                    :
                    <>
                    <img src={props.track.images[5].url} alt=""/>
                    <button className="p btn-listen" onClick={props.listen}>LISTEN</button>
                    </>
                  }
                </div>
            <h5>{props.track.artist.name}</h5>
            <p>{props.track.title}</p>
            <p>{props.track.catNumber}</p>
            <div className="single-track-buttons">
              {props.isShop ?
                <Link to={'/release/'+ props.track.id} className="btn-buy">BUY</Link>
                :
                <>
                  <Link to={'/release/'+ props.track.id} className="btn-buy">BUY</Link>
                  <button className="btn-info" onClick={props.open}>INFO</button>
                </>
              }
            </div>
        </div>
    )
}