import {API_ACF_HOST, API_HOST, API_WP_HOST} from "./constants";

export const GET_CATALOG = API_HOST+'/music/releases/'
export const GET_CATALOG_PRODUCTS = API_HOST+'/retail/products?type=release'
export const GET_CATALOG_ALL_PRODUCTS = API_HOST+'/retail/products?allow_preorder=true'
export const GET_PRODUCT = API_HOST+'/retail/products?type=release&reference_type=release&reference_id='
export const GET_PHYSICAL_PRODUCT = API_HOST+'/retail/products/?type=other&reference_type=release&reference_id='
export const GET_TRACK_PRODUCT = API_HOST+'/retail/products?reference_type=release&reference_id='
export const GET_ARTISTS = API_HOST+'/music/artists/'
export const GET_PROJECTS = API_WP_HOST+'/project/'
export const GET_PAGE = API_WP_HOST+'/pages/'
export const GET_LANDING = API_WP_HOST+'/pages?slug='
export const GET_CHILD_PAGES = API_WP_HOST+'/pages?parent='
export const GET_OPTIONS_PAGE = API_ACF_HOST+'/options/options'
export const GET_MEDIAS = API_HOST+'/content/media-items?reference_type=release&type=audio&reference_id='
export const GET_MEDIAS_TRACK = API_HOST+'/content/media-items?reference_type=track&type=audio&reference_id='
export const GET_VIDEOS = API_HOST+'/content/media-items?type=video&reference_type=artist&reference_id='
export const GET_PLAYER = API_HOST+'/content/media-items?reference_type=release&reference_id='
export const GET_EXTERNAL_LINKS = API_HOST+'/content/media-items?reference_type=release&reference_id='
export const GET_ADDITIONAL_IMAGES = API_HOST+'/content/media-items?reference_type=product&type=image&reference_id='
export const GET_ADDITIONAL_IMAGES_FOR_RELEASE = API_HOST+'/content/media-items?reference_type=release&type=image&reference_id='
export const GET_ADDITIONAL_VIDEOS = API_HOST+'/content/media-items?reference_type=release&type=video&reference_id='