import {Dispatch} from "redux";
import {
    GET_ARTISTS_ERROR,
    GET_ARTISTS_LOADING,
    GET_ARTISTS_SUCCESS,
    GET_CONTENT_ARTIST_ERROR,
    GET_CONTENT_ARTIST_LOADING,
    GET_CONTENT_ARTIST_SUCCESS,
    GET_SINGLE_ARTIST_ERROR,
    GET_SINGLE_ARTIST_LOADING,
    GET_SINGLE_ARTIST_SUCCESS,
} from "../../utils/types";
import {GET_ARTISTS, GET_VIDEOS} from "../../utils/apis";
import {getToken} from "./auth";

export const getArtists = () => async (dispatch: Dispatch) => {
    try {
        dispatch({
            type: GET_ARTISTS_LOADING
        });
        const token = await getToken();

        fetch(GET_ARTISTS, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ token
            },
        }).then((response) => response.json())
            .then(json => {
                dispatch({
                    type: GET_ARTISTS_SUCCESS,
                    payload: json.results
                });
            })
    } catch (e) {
        dispatch({
            type: GET_ARTISTS_ERROR
        });
    }
}

export const getSingleArtist = (id:string) => async (dispatch: Dispatch) => {
    try {
        dispatch({
            type: GET_SINGLE_ARTIST_LOADING
        });
        const token = await getToken();

        fetch(GET_ARTISTS + id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ token
            },
        }).then((response) => response.json())
            .then(json => {
                dispatch({
                    type: GET_SINGLE_ARTIST_SUCCESS,
                    payload: json
                });
            })
    } catch (e) {
        dispatch({
            type: GET_SINGLE_ARTIST_ERROR
        });
    }
}

export const getContentByArtist = (id:string) => async (dispatch: Dispatch) => {
    try {
        dispatch({
            type: GET_CONTENT_ARTIST_LOADING
        });
        const token = await getToken();

        fetch(GET_VIDEOS +id , {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ token
            },
        }).then((response) => response.json())
          .then(json => {
              dispatch({
                  type: GET_CONTENT_ARTIST_SUCCESS,
                  payload: json.results
              });
          })
    } catch (e) {
        dispatch({
            type: GET_CONTENT_ARTIST_ERROR
        });
    }
}
