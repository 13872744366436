export const closePlayer = () => {
  let clickEvent = new MouseEvent("click", {
    "view": window,
    "bubbles": true,
    "cancelable": true
  });
  if(document.querySelectorAll('.ochre-playlist-wrapper')[0] && !document.querySelectorAll('.ochre-playlist-wrapper')[0].classList.contains('ochre-hidden')) {
    document.querySelectorAll('.ochre-playlist-toggle.ochre-js-toggle-playlist')[0].dispatchEvent(clickEvent);
  }
}

export const changeColorBackgroundMeta = (color: string) => {
  document.body.style.backgroundColor = color
  document.querySelector('meta[name="theme-color"]')!.setAttribute('content', color)
  if(document.querySelector('.ochre-cart'))
    document.querySelector<HTMLElement>('.ochre-cart')!.style.backgroundColor = color
}

export const convertDate = (d: any) => {
  let date = new Date(d);
  return (((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + ' ' + date.toLocaleString('en', { month: 'long' }) + ' ' + date.getFullYear())
}

export const convertSeconds = (s: any) => {
  const pad = (n:number, z?:number) => {
    z = z || 2;
    return ('00' + n).slice(-z);
  }

  var ms = s % 1000;
  s = (s - ms) / 1000;
  var secs = s % 60;
  s = (s - secs) / 60;
  var mins = s % 60;

  return pad(mins) + ':' + pad(secs);
}