import React, {useEffect, useRef, useState} from "react";
import {Header} from "../../components/Header";
import './style.css';
import logo from '../../../assets/images/planisphere_logo.svg';
import {Footer} from "../../components/Footer";
import {Link, useHistory, useLocation} from "react-router-dom";
import {useMediaQuery} from "react-responsive";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {getPageInfo} from "../../store/actions/project";
import {changeColorBackgroundMeta, closePlayer} from "../../utils/helpers";
import {Helmet} from "react-helmet";

export const HomeScreen = () => {

    const [isLoading, setLoading] = useState(true);
    const [isLoadingHeader, setLoadingHeader] = useState(true);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [offset, setOffset] = useState(0);
    const [color, setColor] = useState('#383337');
    const [headerColor, setHeaderColor] = useState(false);
    const [logoClass, setLogoClass] = useState('');
    const location = useLocation();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const page = useAppSelector((state) => state.project.page);
    const blocksRef = useRef(0);
    blocksRef.current = page && page.acf && page.id === 2 && page.acf.content;
    const isFromAnotherPage = new URLSearchParams(location.search).get('p')
    const isMobile = useMediaQuery({ query: `(max-width: 767px)` })
    useEffect(()=>{
        if(!isFromAnotherPage) {
            if(isMobile) {
                setTimeout(() => {
                    window.onbeforeunload = function () {
                        window.scrollTo(0, 0);
                    }
                    setLoading(false)
                }, 4000)
                setTimeout(() => {
                    setLoadingHeader(false)
                }, 3500)
            }
            else{
                setTimeout(() => {
                    setLoading(false)
                }, 3500)
                setTimeout(() => {
                    setLoadingHeader(false)
                }, 3000)
            }
        }
        else{
            setLoadingHeader(false)
            setTimeout(() => {
                setLoading(false)

            },1500)
        }
    }, [isFromAnotherPage, isMobile])

    useEffect(()=>{
        const queryParams = new URLSearchParams(location.search)

        if (queryParams.has('p')) {
            queryParams.delete('p')
            history.replace({
                search: queryParams.toString(),
            })
        }
    },[history, location.search])

    useEffect(()=>{
        if(isLoading){
            document.body.style.overflow = 'hidden'
        }
        else{
            document.body.style.overflow = 'unset'
        }
    },[isLoading])

    useEffect(()=>{
        document.body.classList.remove('orange-player')
        changeColorBackgroundMeta('#393337');
    }, [])

    useEffect(()=>{
        const makeAsync = async () => {
            await dispatch(getPageInfo(2));
        };
        makeAsync();
    }, [dispatch])

    useEffect(()=>{
        if(color === '#ff4200'){
            setHeaderColor(true)
            localStorage.setItem('playButton', 'dark')
            if(document.querySelectorAll('.ochre-cart-toggle')[0])
                document.querySelectorAll('.ochre-cart-toggle')[0].classList.add('orange-bg')
        }
        else{
            setHeaderColor(false);
            localStorage.setItem('playButton', 'light')
            if(document.querySelectorAll('.ochre-cart-toggle')[0])
                document.querySelectorAll('.ochre-cart-toggle')[0].classList.remove('orange-bg')
        }
    }, [color])

    // useEffect(() => {
    //     closePlayer();
    //     window.onbeforeunload = function () {
    //         window.scrollTo(0, 0);
    //     }
    //     window.addEventListener("scroll", changeBackground);
    //     return () => {
    //         window.removeEventListener("scroll", changeBackground);
    //     };
    // }, [])

    useEffect(() => {
        closePlayer();
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
        const handleScroll = () => {
            setOffset(window.scrollY);
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [])

    useEffect(() => {
        const windowHeight = document.body.scrollHeight;
        const blockHeight = windowHeight / 3;
        const calculateBackground = () => {
            const scrolledBlockIndex = Math.floor(offset / blockHeight);

            if (scrolledBlockIndex === 0) {
                setColor('#383337');
            } else if (scrolledBlockIndex === 1) {
                setColor('#898E99');
            } else if (scrolledBlockIndex === 2) {
                setColor('#ff4200');
            }

            if(window.scrollY > 10){
                setLogoClass('animate')
            }
            else{
                setLogoClass('')
            }
        };

        calculateBackground();
    }, [offset]);

    // const changeBackground = () => {
    //     setOffset(window.scrollY)
    //
    //     // @ts-ignore
    //     if(blocksRef && blocksRef.current.length > 0) {
    //         // @ts-ignore
    //         // eslint-disable-next-line array-callback-return
    //         blocksRef.current.map((ref: any) => {
    //
    //             if (ref.current) {
    //                 const bg = ref.current.getAttribute('data-background');
    //                 ref.current.getBoundingClientRect().top < window.innerHeight / 1.5 && setColor(bg)
    //             }
    //         });
    //     }
    //     if(window.scrollY > 10){
    //         setLogoClass('animate')
    //     }
    //     else{
    //         setLogoClass('')
    //     }
    // }

    return (
      <>
          <main className="home-wrapper" style={{backgroundColor: color}}>
              {Object.keys(page).length !== 0 && page.id === 2 &&
                <Helmet>
                    <title>{page.acf.meta_title}</title>
                    <meta name="description" content={page.acf.meta_description} />
                    <meta property="og:image" content={page.acf.meta_image} />
                    <meta property="og:title" content={page.acf.meta_title} />
                    <meta property="og:description" content={page.acf.meta_description} />
                </Helmet>
              }
              <section className={isLoading ? "hero" : "hero active"}>
                  <div className={isLoadingHeader ? "loader" : "loader hide"}>
                      <p className="h4">activating the unknown</p>
                      <img src={logo} alt="" className={logoClass + " logo"}/>
                  </div>
                  <Header show={isLoadingHeader} color={headerColor} hidelogo/>
                  <div className={isLoading ? "home-content" : "home-content active"}>
                      {Object.keys(page).length !== 0 && page.id === 2 &&
                      <>
                          {page.acf.content.map((layout:any, index: number) => (
                            layout.acf_fc_layout === 'image_block' ?
                              <div
                                className={`home-image-block ${layout.position} ${layout.size === '60' ? 'small' : layout.size === '90' ? 'large' : ''}`}
                                key={index}
                                // @ts-ignore
                                ref={blocksRef && blocksRef.current[index]}
                                data-background={layout.background}
                              >
                                  <div className="home-image-top">
                                      <p className={`p ${layout.color}`}>{layout.subtitle}</p>
                                      <Link to={layout.link}>
                                          <h5 className={`h5 ${layout.color}`}>{layout.title}</h5>
                                      </Link>
                                  </div>
                                  <div className="home-image-content">
                                      <Link to={layout.link}>
                                          <img src={layout.image.url} alt=""/>
                                      </Link>
                                  </div>
                              </div>
                              : layout.acf_fc_layout === 'listenread_button' ?
                              <div
                                // @ts-ignore
                                ref={blocksRef && blocksRef.current[index]}
                                key={index}
                                className="home-button-block"
                              >
                                  <a href={layout.link}
                                    className="release-top"
                                     target={layout.open_a_new_tab ? '_blank' : '_self'}
                                     rel="noreferrer"
                                  >
                                      {layout.subtitle &&
                                      <p className="p orange">{layout.subtitle}</p>
                                      }
                                      <h2 className="h2 orange">{layout.title}</h2>
                                  </a>
                              </div>
                              :
                              <></>
                          ))}
                      </>
                      }
                  </div>
              </section>
              <Footer/>
          </main>
      </>
    )
}