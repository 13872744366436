import {
    GET_RELEASE_INFO_ERROR,
    GET_RELEASE_INFO_LOADING,
    GET_RELEASE_INFO_SUCCESS,
    GET_RELEASES_ERROR,
    GET_RELEASES_LOADING,
    GET_RELEASES_SUCCESS,
    GET_SINGLE_RELEASE_ERROR,
    GET_SINGLE_RELEASE_LOADING,
    GET_SINGLE_RELEASE_SUCCESS,
    GET_TRACKS_ARTIST_ERROR,
    GET_TRACKS_ARTIST_LOADING,
    GET_TRACKS_ARTIST_SUCCESS,
    GET_TRACKS_FORMAT_ERROR, GET_TRACKS_FORMAT_LOADING,
    GET_TRACKS_FORMAT_SUCCESS,
    GET_TRACKS_RELEASE_LOADING,
    GET_TRACKS_RELEASE_SUCCESS,
    PLAYER_SUCCESS
} from "../../utils/types";

const initialState = {
    releases: [],
    release: [],
    tracks: [],
    releaseTracks: [],
    tracksArtist: [],
    tracksFormat: [],
    shopTrack: [],
    shopProduct: [],
    shopLinks: [],
    images: [],
    videos: [],
    isLoading: false,
    isLoadingArtist: false,
    isLoadingShop: false,
    isLoadingFormat: false,
    isError: false,
    isErrorArtist: false,
    isLoadingSingle: false,
    isErrorSingle: false,
    isErrorShop: false,
    isErrorFormat: false,
    isLoadingReleaseTracks: false,
    player: undefined
}

interface Action {
    type: string;
    payload?: any
}

export const trackReducer = (state = initialState, action:Action) => {
    switch (action.type) {
        case GET_RELEASES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                releases: action.payload,
                isError: false
            }
        case GET_RELEASES_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
        case GET_RELEASES_LOADING:
            return {
                ...state,
                isLoading: true,
                isError: false
            }
        case GET_SINGLE_RELEASE_SUCCESS:
            return {
                ...state,
                isLoadingSingle: false,
                release: action.payload.description,
                tracks: action.payload.tracks,
                isErrorSingle: false
            }
        case GET_SINGLE_RELEASE_ERROR:
            return {
                ...state,
                isLoadingSingle: false,
                isErrorSingle: true,
            }
        case GET_SINGLE_RELEASE_LOADING:
            return {
                ...state,
                isLoadingSingle: true,
                isErrorSingle: false
            }
        case GET_TRACKS_ARTIST_SUCCESS:
            return {
                ...state,
                isLoadingArtist: false,
                tracksArtist: action.payload,
                isErrorArtist: false
            }
        case GET_TRACKS_ARTIST_ERROR:
            return {
                ...state,
                isLoadingArtist: false,
                isErrorArtist: true,
            }
        case GET_TRACKS_ARTIST_LOADING:
            return {
                ...state,
                isLoadingArtist: true,
                isErrorArtist: false
            }
        case GET_RELEASE_INFO_SUCCESS:
            return {
                ...state,
                isLoadingShop: false,
                shopTrack: action.payload.track,
                shopProduct: action.payload.product,
                shopLinks: action.payload.links,
                images: action.payload.images,
                videos: action.payload.videos,
                isErrorShop: false
            }
        case GET_RELEASE_INFO_ERROR:
            return {
                ...state,
                isLoadingShop: false,
                isErrorShop: true,
            }
        case GET_RELEASE_INFO_LOADING:
            return {
                ...state,
                isLoadingShop: true,
                isErrorShop: false
            }
        case PLAYER_SUCCESS:
            return {
                ...state,
                player: action.payload
            }
        case GET_TRACKS_RELEASE_SUCCESS:
            return {
                ...state,
                isLoadingReleaseTracks: false,
                releaseTracks: action.payload
            }
        case GET_TRACKS_RELEASE_LOADING:
            return {
                ...state,
                isLoadingReleaseTracks: true,
            }
        case GET_TRACKS_FORMAT_SUCCESS:
            return {
                ...state,
                isLoadingFormat: false,
                tracksFormat: action.payload,
                isErrorFormat: false
            }
        case GET_TRACKS_FORMAT_ERROR:
            return {
                ...state,
                isLoadingFormat: false,
                isErrorFormat: true,
            }
        case GET_TRACKS_FORMAT_LOADING:
            return {
                ...state,
                isLoadingFormat: true,
                isErrorFormat: false
            }
        default:
            return state
    }
}