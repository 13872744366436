import React, {useEffect, useState} from "react";
import {Header} from "../../components/Header";
import './style.css';
import {Footer} from "../../components/Footer";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {getChildPages} from "../../store/actions/project";
import {changeColorBackgroundMeta, closePlayer} from "../../utils/helpers";

export const ParentLandingScreen = () => {

  const dispatch = useAppDispatch();
  const pages = useAppSelector((state) => state.project.page);
  const isLoading = useAppSelector((state) => state.project.isLoadingPage);
  const [isLoadingCustom, setLoadingCustom] = useState(true)

  useEffect(() => {
    const makeAsync = async () => {
      await dispatch(getChildPages(634));
    };
    makeAsync();
  }, [dispatch])

  useEffect(() => {
    closePlayer();
    document.body.classList.add('orange-player')
    document.body.classList.remove('scroll-down')
    changeColorBackgroundMeta('#393337');
  }, [])

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        setLoadingCustom(false)
      }, 500)
    }
  }, [isLoading])

  return (
    <main>
      <div className={isLoadingCustom ? 'custom-loader' : 'custom-loader hide'}>
        <p>loading...</p>
      </div>
      <section className="privacy">
        <Header/>
        {!isLoading && pages && pages.length > 0 ?
          pages.map((page: any, index: number) => {
            return (
            <div className="home-button-block" key={index}>
              <a href={page.link}
                 className="release-top"
                 rel="noreferrer"
              >
                <h2 className="h2 orange"><div dangerouslySetInnerHTML={{ __html: page.title.rendered }} /></h2>
              </a>
            </div>
            )
          })
          :
          <></>
        }
      </section>
      <Footer transparent={true}/>
    </main>
  )
}