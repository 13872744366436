import React, {useEffect, useState} from "react";
import {Header} from "../../components/Header";
import './style.css';
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {getCatalog} from "../../store/actions/track";
import {Footer} from "../../components/Footer";
import {changeColorBackgroundMeta, closePlayer} from "../../utils/helpers";
import {Release} from "../../components/Release";

export const ShopScreen = () => {

    const tracks = useAppSelector((state) => state.track.releases);
    const isLoading = useAppSelector((state) => state.track.isLoading);
    const [isLoadingCustom, setLoadingCustom] = useState(true)
    const [loader, setLoader] = useState(false)
    const [releases, setReleases] = useState<any>([])
    const dispatch = useAppDispatch();

    useEffect(() => {
        const makeAsync = async () => {
            await dispatch(getCatalog());
            setLoader(true)
        };
        makeAsync();
    }, [dispatch]);

    useEffect(()=>{
        if(!isLoading){
            setTimeout(()=>{
                setLoadingCustom(false)
            },1000)
        }
    }, [isLoading])

    useEffect(()=>{
        closePlayer();
        document.body.classList.add('orange-player')
        document.body.classList.remove('scroll-down')
        changeColorBackgroundMeta('#1E1E1E');
    }, [])

    const trackItems = releases.map((track:any) => {
        return (
          <Release key={track.id} track={track} isLoading={isLoading}/>
        )
    });

    useEffect(()=>{
        if(tracks && tracks.length > 0 && loader && !isLoading) {
            const releases = Object.values(tracks.reduce((result: any, obj: any) => {
                (result[obj.group.id] || (result[obj.group.id] = [])).push(obj);
                return result;
            }, {}));

            const getByOne = releases.map((release: any) => release[0])
            // @ts-ignore
            const sorted = getByOne.sort((getByOne,b) => new Date(b.dateAvailable) - new Date(getByOne.dateAvailable))
            setReleases(sorted)
        }
        // eslint-disable-next-line
    }, [tracks, loader])

    return (
        <main>
            <div className={isLoadingCustom ? 'custom-loader' : 'custom-loader hide'}>
                <p>loading...</p>
            </div>
            <section className="catalog">
                <Header/>
                {!isLoading && tracks.length > 0 ?
                    <div className="tracks">
                        {trackItems}
                    </div>
                    :
                    null
                }
            </section>
            <Footer transparent/>
        </main>
    )
}