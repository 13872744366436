import React from "react";
import {Header} from "../../components/Header";
import './style.css';
import back from '../../../assets/images/circles.png';
import {Footer} from "../../components/Footer";

export const PartnershipScreen = () => {

    return (
        <main>
            <section className="partnership">
                <Header/>
                <div className="partnership-wrapper">
                    <div className="left-side">
                        <img src={back} alt=""/>
                        <h2 className="h2 orange">Thought <br/>Partnership</h2>
                    </div>
                    <div className="right-side">
                        <p>The digital transformation of media and culture poses amazing opportunities and tremendous challenges at the same time, fundamentally changing the way we interact, and how information is distributed. This oversupply of information can lead to the present and future scarcity of the only resource that activates opportunities and turns them into shared experiences: Attention.</p>
                        <p>Planisphere’s progressive ambition to comprehend the process of dematrialization through digital progression has lead the agency to a deep understanding of the mechanics of this transformation and an extensive toolbox of how organizations, institutions or individuals can use these technological developments to reach their audiences.</p>
                    </div>
                </div>
            </section>
            <Footer transparent={true}/>
        </main>
    )
}