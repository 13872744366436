import React from "react";
import {Header} from "../../components/Header";
import './style.css';
import back from '../../../assets/images/info-logo.png';
import {Footer} from "../../components/Footer";

export const InfoScreen = () => {

    return (
        <main>
            <section className="info">
                <Header/>
                <div className="info-wrapper">
                    <div className="left-side">
                        <img src={back} alt=""/>
                        <h2 className="h2 orange">Info Text</h2>
                    </div>
                    <div className="right-side">
                        <p>Planisphere is a network-based organisation for thought partnership and creative production. Founded in 2017 the Basel-based  has established a distinctive network with nodes to Barcelona, Berlin, London, Paris and Zürich. Together with singular talents and international professionals Planisphere conceptualizes and realizes visionary projects for clients, who want to actively shape their future.</p>
                        <p>Beyond their service based work, Planisphere draws from a knowledge base that includes experiences within the fields of music, design, fashion, film, performance, subculture, technology and urbanism, with a track record for delivering self-initiated, multi-disciplinary projects.</p>
                        <p>By constantly researching and questioning the status quo of cultural contexts, and by initiating debates around the topic of dematerialization through digital progression, Planisphere focuses its work on activating unknown creative territories through holistic thinking and design processes.</p>
                    </div>
                </div>
            </section>
            <Footer transparent={true}/>
        </main>
    )
}