import React from "react";
import './style.css';
// import {PAYMENT_HOST} from "../../utils/constants";
import {Link} from "react-router-dom";

export const Release = (props:any) => {

    return (
      <div className="single-track rls">
        <div className="single-track-image">
          <Link to={'/release/' + props.track.group.id}>
            {props.track.images &&
            <img src={props.track.images[5].url} alt=""/>
            }
          </Link>
        </div>
        <h5>{props.track.references && props.track.references.map((ref:any) => ref.type === 'artist') ? props.track.references.filter((ref:any) => ref.type === 'artist')[0].resource.name : ''}</h5>
        <p>{props.track.title}</p>
        <p>{props.track.references && props.track.references.filter((ref:any) => ref.type === 'release').length > 0 ? props.track.references.filter((ref:any) => ref.type === 'release')[0].resource.catNumber : ''}</p>
        <div className="single-track-buttons">
          <Link to={'/release/'+ props.track.group.id} className="btn-buy">BUY</Link>

        </div>
      </div>
    )
}