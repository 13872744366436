import React, {useEffect, useState} from "react";
import {Header} from "../../components/Header";
import './style.css';
import {Footer} from "../../components/Footer";
import {Artist} from "../../components/Artist";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {getArtists} from "../../store/actions/artist";
import {changeColorBackgroundMeta, closePlayer} from "../../utils/helpers";

export const ArtistsScreen = () => {

    const dispatch = useAppDispatch();
    const artists = useAppSelector((state) => state.artist.artists);
    const isLoading = useAppSelector((state) => state.artist.isLoading);
    const [isLoadingCustom, setLoadingCustom] = useState(true)

    useEffect(() => {
        if(artists.length === 0) {
            const makeAsync = async () => {
                await dispatch(getArtists());
            };
            makeAsync();
        }
    }, [artists.length, dispatch]);

    useEffect(()=>{
        if(!isLoading){
            setTimeout(()=>{
                setLoadingCustom(false)
            },1000)
        }
    }, [isLoading])

    const artistsItems = artists.slice(0).reverse().map((artist:any) =>
        <Artist key={artist.id} artist={artist}/>
    );

    useEffect(()=>{
        closePlayer();
        document.body.classList.add('orange-player')
        document.body.classList.remove('scroll-down')
        changeColorBackgroundMeta('#1e1e1e');
    }, [])

    return (
        <main>
            <section className="artists-section">
                <div className={isLoadingCustom ? 'custom-loader' : 'custom-loader hide'}>
                    <p>loading...</p>
                </div>
                <Header/>
                {!isLoading && artists.length > 0 ?
                    <div className="artists">
                        {artistsItems}
                    </div>
                    :
                    null
                }
            </section>
            <Footer transparent={true}/>
        </main>
    )
}