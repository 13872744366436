import React, {useState} from "react";
import {VideoFile} from "./video-file";
import YouTube from "react-youtube";

export const VideoBlock = ({layout, withButtons}:{layout:any, withButtons?: boolean}) => {

  const [player, setPlayer] = useState<any>();
  const [isPlay, togglePlay] = useState(false)
  const [isMute, toggleMute] = useState(false)
  const [isFull, toggleFull] = useState(false)

  const playHandler = () => {
    if(isPlay) {
      player.pauseVideo();
    }
    else{
      player.playVideo();
    }
    togglePlay(!isPlay)
  }

  const muteHandler = () => {
    if(isMute){
      player.unMute();
    }
    else{
      player.mute();
    }
    toggleMute(!isMute)
  }

  const fullHandler = (event:any) => {
    event.preventDefault();
    toggleFull(!isFull)
    const el:any = document.getElementById(`player${layout.iframe_link.split("embed/")[1]}`);
    if (el.requestFullscreen) {
      el.requestFullscreen();
    } else if (el.msRequestFullscreen) {
      el.msRequestFullscreen();
    } else if (el.mozRequestFullScreen) {
      el.mozRequestFullScreen();
    } else if (el.webkitRequestFullscreen) {
      el.webkitRequestFullscreen();
    }
  }

  return (
    layout.iframe ?
      (
        layout.youtube ?
          <>
            <div className={`iframe-container ${layout.iframe_height === 'small' ? 'small-height' : ''}`}>
              <YouTube
                videoId={layout.iframe_link.split("embed/")[1]}
                className='iframe' id={`player${layout.iframe_link.split("embed/")[1]}`}
                onReady={(event)=>setPlayer(event.target)}
                onPlay={()=>togglePlay(true)}
                onPause={()=>togglePlay(false)}
              />
            </div>
            <div className={'video-buttons'}>
              <button className='small-btn' onClick={playHandler}>{isPlay ? 'PAUSE' : 'PLAY'}</button>
              <button className='small-btn' onClick={muteHandler}>{isMute ? 'UNMUTE' : 'MUTE'}</button>
              <button className='small-btn' onClick={fullHandler}>FULLSCREEN</button>
            </div>
          </>
          :
          <div className={`iframe-container ${layout.iframe_height === 'small' ? 'small-height' : ''}`}>
            <iframe
              src={layout.iframe_link}
              className="iframe"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              title='video1'
            />
          </div>
      )
      :
      (
        <VideoFile link={layout.video.url} withButtons={withButtons}/>
      )
  )
}