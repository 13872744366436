import React, {useRef, useState} from "react";

export const VideoFile = ({link, withButtons}:{link: string, withButtons?: boolean}) => {

  const [isPlay, togglePlay] = useState(true)
  const [isMute, toggleMute] = useState(true)
  const [isFull, toggleFull] = useState(false)
  const vidRef = useRef<any>(null);
  const playHandler = () => {
    if(isPlay) {
      vidRef.current.pause();
    }
    else{
      vidRef.current.play();
    }
    togglePlay(!isPlay)
  }

  const muteHandler = () => {
    vidRef.current.muted = !isMute;
    toggleMute(!isMute)
  }

  const fullHandler = (event:any) => {
    event.preventDefault();
    toggleFull(!isFull)
    const el = vidRef.current
    if (el.requestFullscreen) {
      el.requestFullscreen();
    } else if (el.msRequestFullscreen) {
      el.msRequestFullscreen();
    } else if (el.mozRequestFullScreen) {
      el.mozRequestFullScreen();
    } else if (el.webkitRequestFullscreen) {
      el.webkitRequestFullscreen();
    }
  }

  return (
    <>
      <video autoPlay muted loop playsInline ref={vidRef}>
        <source src={link}/>
      </video>
      {withButtons &&
      <div className={'video-buttons'}>
          <button className='small-btn' onClick={playHandler}>{isPlay ? 'PAUSE' : 'PLAY'}</button>
          <button className='small-btn' onClick={muteHandler}>{isMute ? 'UNMUTE' : 'MUTE'}</button>
          <button className='small-btn' onClick={fullHandler}>FULLSCREEN</button>
      </div>
      }
    </>
  )
}