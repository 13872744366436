import React, {useEffect, useState} from "react";
import './style.css';
import {Footer} from "../../components/Footer";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {getContentByArtist, getSingleArtist} from "../../store/actions/artist";
import { useParams } from 'react-router';
import {getTracksByArtist} from "../../store/actions/track";
import {Link, useHistory} from "react-router-dom";
import {changeColorBackgroundMeta, closePlayer} from "../../utils/helpers";
import {IframeVideo} from "../../components/ACF/iframe-video";

export const ArtistScreen = () => {

    // @ts-ignore
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const artist = useAppSelector((state) => state.artist.single);
    const tracks = useAppSelector((state) => state.track.tracksArtist);
    const videos = useAppSelector((state) => state.artist.videos);
    const isLoading = useAppSelector((state) => state.artist.isLoadingSingle);
    const isLoadingArtist = useAppSelector((state) => state.track.isLoadingArtist);
    const [isLoadingCustom, setLoadingCustom] = useState(true)
    const [halfImage, setHalfImage] = useState(false)
    const history = useHistory();
    const body = document.body;
    const scrollUp = "scroll-up";
    const scrollDown = "scroll-down";
    let lastScroll = 0;

    const onImgLoad = (e: any) => {
        const { naturalWidth, naturalHeight } = e.target;
        if(naturalHeight > naturalWidth){
            setHalfImage(true)
        }
    };

    useEffect(()=>{
        closePlayer();
        document.body.classList.add('orange-player')
        document.body.classList.remove('scroll-down')
        changeColorBackgroundMeta('#010102');
    }, [])

    useEffect(() => {
        window.addEventListener("scroll", headerHandler);
        return () => {
            window.removeEventListener("scroll", headerHandler);
        };
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const makeAsync = async () => {
            await dispatch(getSingleArtist(id));
            await dispatch(getContentByArtist(id));
            await dispatch(getTracksByArtist(id));
        };
        makeAsync();
    }, [dispatch, id]);

    useEffect(()=>{
        if(!isLoading){
            setTimeout(()=>{
                setLoadingCustom(false)
            },1000)
        }
    }, [isLoading])

    const headerHandler = () => {
        const currentScroll = window.pageYOffset;
        if (currentScroll <= 0) {
            body.classList.remove(scrollUp);
            return;
        }

        if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
            // down
            body.classList.remove(scrollUp);
            body.classList.add(scrollDown);
        } else if (
          currentScroll < lastScroll &&
          body.classList.contains(scrollDown)
        ) {
            // up
            body.classList.remove(scrollDown);
            body.classList.add(scrollUp);
        }
        lastScroll = currentScroll;
    }

    const HeaderArtist = () => (
      <div className="header-artist">
          <button onClick={history.goBack} className="p header-artist-close">close</button>
          <h5 className="h5 orange">{artist.name}</h5>
          <div/>
      </div>
    )

    return (
        <main>
            <section className="single-artist-section">
                <HeaderArtist/>
                <div className={isLoadingCustom ? 'custom-loader' : 'custom-loader hide'}>
                    <p>loading...</p>
                </div>
                {!isLoading && artist.name &&
                    <>
                        <div className="artist-top">
                            <img src={artist.images[4].url} alt="" onLoad={onImgLoad} className={halfImage ? 'half-image' : ''}/>
                            <h5 className="h5 orange">{artist.name}</h5>
                        </div>
                        <div className="artist-text">
                            <p className="p">{artist.description}</p>
                        </div>
                    </>
                }
                {!isLoadingArtist && videos.length > 0 &&
                <>
                    <h3 className="h5 title">Media</h3>
                    <div className="artist-videos">
                        {videos.map((video:any) => (
                          <IframeVideo video={video} key={video.id}/>
                          )
                        )}
                    </div>
                </>
                }
                {!isLoadingArtist && tracks.length > 0 &&
                    <>
                        <h3 className="h5 title">Releases</h3>
                        <div className="artist-tracks">
                            {tracks.map((track:any) =>
                                <Link to={'/release/'+track.id} key={track.id} className="artist-single-track">
                                    <h6 className="h6">{track.artist.name}</h6>
                                    <p className="p">{track.title}</p>
                                    <p className="p">{track.catNumber}</p>
                                    <img src={track.imageUrl} alt=""/>
                                </Link>
                            )}
                        </div>
                    </>
                }
            </section>
            <Footer transparent={true}/>
        </main>
    )
}