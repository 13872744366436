import React from "react";
import {useHistory} from 'react-router-dom';
import './style.css';
import ReactPlaceholder from "react-placeholder";

export const Artist = (props:any) => {
    const history = useHistory();
    const handleOnClick = (id:string) => history.push('/artist/'+id);

    return (
      props.artist.images[5] ? (
        <div className="single-artist">
            <ReactPlaceholder type='media' ready={!props.isLoading}>
                <div className="single-artist-image">

                  <img src={props.artist.images[5].url} alt="" onClick={() => handleOnClick(props.artist.id)}/>
                </div>
            </ReactPlaceholder>
            <h5>{props.artist.name}</h5>
          <button className="btn-buy" onClick={()=>handleOnClick(props.artist.id)}>INFO</button>
        </div>
      )
        :
        null
    )
}