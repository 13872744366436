import React, {useEffect, useState} from "react";
import {Header} from "../../components/Header";
import './style.css';
import {useParams} from "react-router";
import {Footer} from "../../components/Footer";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {getSingleProject} from "../../store/actions/project";
import {changeColorBackgroundMeta} from "../../utils/helpers";
import {Helmet} from "react-helmet";

export const ProjectScreen = () => {

  const dispatch = useAppDispatch();
  const [isOpen, setOpen] = useState(false);
  const [color, setColor] = useState('#000');
  const isLoading = useAppSelector((state) => state.project.isLoadingSingle);
  const [isLoadingCustom, setLoadingCustom] = useState(true)
  const project = useAppSelector((state) => state.project.singleProject);
  let { id }: any = useParams();

  useEffect(() => {
    setOpen(false)
    const makeAsync = async () => {
      await dispatch(getSingleProject(id));
    };
    makeAsync();
  },[id, dispatch]);

  useEffect(()=>{
    if(!isLoading){
      setTimeout(()=>{
        setLoadingCustom(false)
      },1000)
    }
  }, [isLoading])

  useEffect(()=>{
    if(project && project.acf && project.acf.background_color)
      setColor(project.acf.background_color ? project.acf.background_color : '#1E1E1E')
  },[project])

  useEffect(()=>{
    document.body.classList.add('orange-player')
    document.body.classList.remove('scroll-down')
    changeColorBackgroundMeta(color);
  }, [color])

  return (
    <main>
      {!isLoading && Object.keys(project).length !== 0 && project.acf &&
          <Helmet>
              <title>{project.acf.meta_title}</title>
              <meta name="description" content={project.acf.meta_description} />
              <meta property="og:image" content={project.acf.meta_image} />
              <meta property="og:title" content={project.acf.meta_title} />
              <meta property="og:description" content={project.acf.meta_description} />
          </Helmet>
      }
      <div className={isLoadingCustom ? 'custom-loader' : 'custom-loader hide'}>
        <p>loading...</p>
      </div>
      <Header open={isOpen}/>
      { !isLoading && Object.keys(project).length !== 0 && project.acf ?
        <>
        <section className="single-project-full" style={{backgroundColor: project.acf.background_color}}>
          <div className='single-project-top'>
            {project.acf.project[0].hero_is_video ?
              project.acf.project[0].video_is_iframe ?
                <div className="iframe-container">
                  <iframe
                    src={project.acf.project[0].iframe_link}
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    title={"project"+id}
                    className="iframe"
                  />
                </div>
                :
                <video autoPlay muted loop playsInline>
                  <source src={project.acf.project[0].video.url}/>
                </video>
              :
              <img src={project.acf.project[0].image.url} alt=""/>
            }
            <h2 className="h2 orange">{!project.acf.project[0].is_title ? project.acf.project[0].hero_title : project.title.rendered}</h2>
          </div>
          <div className="container">
            {project.acf.project.map((layout:any, index: number) => (
              layout.acf_fc_layout === 'text_block' ?
                <div className={`single-project-text-content ${layout.width === 'large' ? 'large' : ''}`} key={index}>
                  {layout.layout === 'right' ?
                    <div className="left-side" dangerouslySetInnerHTML={{__html: layout.content}}/>
                    :
                    <>
                      <div className={`left-side ${!layout.title && !layout.subtitle ? 'empty' : ''}`}>
                        <p className="h5" dangerouslySetInnerHTML={{__html: layout.title}}/>
                        <p className="p">{layout.subtitle}</p>
                      </div>
                      <div className="right-side" dangerouslySetInnerHTML={{ __html: layout.content }}/>
                    </>
                  }
                </div>
                : layout.acf_fc_layout === 'image_block' ?
                <div className={"single-project-image-block"} key={index}>
                  {layout.two_images_in_the_row ?
                    <div>
                      {layout.images.map((image:any, i: number) => (
                        <img src={image.image.url} alt="" key={i}/>
                      ))}
                    </div>
                    :
                    <img src={layout.image.url} alt="" className={layout.position}/>
                  }
                </div>
                : layout.acf_fc_layout === 'video_block' ?
                  layout.iframe ?
                    <div className={`iframe-container ${layout.iframe_height === 'small' ? 'small-height' : ''}`} key={index}>
                      <iframe
                        src={layout.iframe_link}
                        className="iframe"
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        title='video1'
                      />
                    </div>
                    :
                    <video autoPlay muted loop playsInline key={index} className={`video-block ${layout.position}`}>
                      <source src={layout.video.url} type="video/mp4"/>
                    </video>
                  : layout.acf_fc_layout === 'listenread_button' ?
                    <div className="listen" key={index}>
                      <button onClick={()=>window.open(layout.link, layout.open_a_new_tab ? '_blank' : '')} className="h2" >{layout.title}</button>
                    </div>
                    :
                    null
            ))}
          </div>
        </section>
        <Footer transparent={true} style={{backgroundColor: project.acf.background_color}}/>
        </>
        :
        <></>
      }
    </main>
  )
}