import React from "react";
import {BrowserRouter as Router, Route, Switch,} from "react-router-dom";
import {HomeScreen} from "../screens/Home";
import {CatalogScreen} from "../screens/Catalog";
import {ProjectScreen} from "../screens/Project";
import {PartnershipScreen} from "../screens/Partnership";
import {ProductionScreen} from "../screens/Production";
import {ArtistsScreen} from "../screens/Artists";
import {ArtistScreen} from "../screens/Artist";
import {ArchitectureScreen} from "../screens/Architecture";
import {InfoScreen} from "../screens/Info";
import {PublishingScreen} from "../screens/Publishing";
import {AboutScreen} from "../screens/About";
import ScrollToTop from "../components/ScrollToTop";
import {ReleaseScreen} from "../screens/Release";
import {ShopScreen} from "../screens/Shop";
import {PrivacyScreen} from "../screens/Privacy";
import {ImpressumScreen} from "../screens/Impressum";
import {ServicesScreen} from "../screens/Services";
import {LandingScreen} from "../screens/Landing";
import {ParentLandingScreen} from "../screens/ParentLanding";

export const Routes = () => {

  return (
    <Router>
      <ScrollToTop/>
      <Switch>
        <Route exact path={'/'}>
          <HomeScreen/>
        </Route>
        <Route path={'/about'}>
          <AboutScreen/>
        </Route>
        <Route path={'/services'}>
          <ServicesScreen/>
        </Route>
        <Route path={'/catalog'}>
          <CatalogScreen/>
        </Route>
        <Route path={'/project/:id'}>
          <ProjectScreen/>
        </Route>
        <Route path={'/partnership'}>
          <PartnershipScreen/>
        </Route>
        <Route path={'/production'}>
          <ProductionScreen/>
        </Route>
        {/*<Route path={'/label-services'}>*/}
        {/*  <LabelScreen/>*/}
        {/*</Route>*/}
        <Route path={'/artists'}>
          <ArtistsScreen/>
        </Route>
        <Route path={'/artist/:id'}>
          <ArtistScreen/>
        </Route>
        <Route path={'/architecture'}>
          <ArchitectureScreen/>
        </Route>
        <Route path={'/info'}>
          <InfoScreen/>
        </Route>
        <Route path={'/publishing'}>
          <PublishingScreen/>
        </Route>
        <Route path={'/shop'}>
          <ShopScreen/>
        </Route>
        <Route path={'/release/:id'}>
          <ReleaseScreen/>
        </Route>
        <Route path={'/privacy'}>
          <PrivacyScreen/>
        </Route>
        <Route path={'/impressum'}>
          <ImpressumScreen/>
        </Route>
        <Route path={'/access/:slug'}>
          <LandingScreen/>
        </Route>
        <Route path={'/access'}>
          <ParentLandingScreen/>
        </Route>
      </Switch>
    </Router>
  )
}