import React, {useState} from "react";

export const ListBlock = ({layout, isClickable}:{layout:any, isClickable: boolean}) => {

  const [numberAccordion, setNumberAccordion] = useState(-1);
  const [open, setOpen] = useState(false);

  const aboutHandler = (index: number) => {
    setNumberAccordion(index)
    setOpen(!open)
  }

  const AccordionItem = ({item, index, isClickable}:{item:any, index: number, isClickable: boolean}) => (
    <li onClick={isClickable ? ()=>aboutHandler(index) : ()=>{}} className={numberAccordion === index && open ? "active" : ''} key={index}>
      <p className="large">{item.title}</p>
      <div className="about-content" dangerouslySetInnerHTML={{__html: item.text}}/>
    </li>
  )

  return (
    <ul className='list-accordion'>
      {layout.list.map((item: any, i:number) => (
        <AccordionItem item={item} index={i} key={i} isClickable={isClickable}/>
      ))}
    </ul>
  )
}