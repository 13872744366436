import {
    GET_LANDINGS_ERROR, GET_LANDINGS_LOADING,
    GET_LANDINGS_SUCCESS,
    GET_PAGE_ERROR,
    GET_PAGE_LOADING,
    GET_PAGE_OPTIONS_ERROR,
    GET_PAGE_OPTIONS_LOADING,
    GET_PAGE_OPTIONS_SUCCESS,
    GET_PAGE_SUCCESS,
    GET_PROJECTS_ERROR,
    GET_PROJECTS_LOADING,
    GET_PROJECTS_SUCCESS,
    GET_SINGLE_LANDING_ERROR, GET_SINGLE_LANDING_LOADING,
    GET_SINGLE_LANDING_SUCCESS,
    GET_SINGLE_PROJECT_ERROR,
    GET_SINGLE_PROJECT_LOADING,
    GET_SINGLE_PROJECT_SUCCESS
} from "../../utils/types";

const initialState = {
    projects: [],
    landings: [],
    page: [],
    options: [],
    singleProject: [],
    singleLanding: [],
    isLoading: false,
    isLoadingPage: false,
    isLoadingSingle: false,
    isLoadingLanding: false,
    isLoadingOptions: false,
    isError: false,
    isErrorSingle: false,
    isErrorLanding: false,
    isErrorPage: false,
    isErrorOptions: false,
}

interface Action {
    type: string;
    payload?: any
}

export const projectReducer = (state = initialState, action:Action) => {
    switch (action.type) {
        case GET_PAGE_OPTIONS_SUCCESS:
            return {
                ...state,
                isLoadingOptions: false,
                options: action.payload,
                isErrorOptions: false
            }
        case GET_PAGE_OPTIONS_ERROR:
            return {
                ...state,
                isLoadingOptions: false,
                isErrorOptions: true,
            }
        case GET_PAGE_OPTIONS_LOADING:
            return {
                ...state,
                isLoadingOptions: true,
                isErrorOptions: false
            }
        case GET_PAGE_SUCCESS:
            return {
                ...state,
                isLoadingPage: false,
                page: action.payload,
                isErrorPage: false
            }
        case GET_PAGE_ERROR:
            return {
                ...state,
                isLoadingPage: false,
                isErrorPage: true,
            }
        case GET_PAGE_LOADING:
            return {
                ...state,
                isLoadingPage: true,
                isErrorPage: false
            }
        case GET_PROJECTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                projects: action.payload,
                isError: false
            }
        case GET_PROJECTS_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
        case GET_PROJECTS_LOADING:
            return {
                ...state,
                isLoading: true,
                isError: false
            }
        case GET_LANDINGS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                landings: action.payload,
                isError: false
            }
        case GET_LANDINGS_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
        case GET_LANDINGS_LOADING:
            return {
                ...state,
                isLoading: true,
                isError: false
            }
        case GET_SINGLE_PROJECT_SUCCESS:
            return {
                ...state,
                isLoadingSingle: false,
                singleProject: action.payload,
                isErrorSingle: false
            }
        case GET_SINGLE_PROJECT_ERROR:
            return {
                ...state,
                isLoadingSingle: false,
                isErrorSingle: true,
            }
        case GET_SINGLE_PROJECT_LOADING:
            return {
                ...state,
                isLoadingSingle: true,
                isErrorSingle: false
            }
        case GET_SINGLE_LANDING_SUCCESS:
            return {
                ...state,
                isLoadingLanding: false,
                singleLanding: action.payload,
                isErrorLanding: false
            }
        case GET_SINGLE_LANDING_ERROR:
            return {
                ...state,
                isLoadingLanding: false,
                isErrorLanding: true,
            }
        case GET_SINGLE_LANDING_LOADING:
            return {
                ...state,
                isLoadingLanding: true,
                isErrorLanding: false
            }
        default:
            return state
    }
}