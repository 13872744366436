import React, {useEffect, useRef, useState} from "react";
import {Header} from "../../components/Header";
import './style.css';
import {Track} from "../../components/Track";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {getCatalog, getReleaseDescription} from "../../store/actions/track";
import {getToken} from "../../store/actions/auth";
import {GET_MEDIAS} from "../../utils/apis";
import {Footer} from "../../components/Footer";
import {changeColorBackgroundMeta, closePlayer} from "../../utils/helpers";

export const CatalogScreen = () => {

    const tracks = useAppSelector((state) => state.track.releases);
    const player = useAppSelector((state) => state.track.player);
    const singleTrack = useAppSelector((state) => state.track.release);
    const list = useAppSelector((state) => state.track.tracks);
    const isLoading = useAppSelector((state) => state.track.isLoading);
    const isSingleLoading = useAppSelector((state) => state.track.isLoadingSingle);
    const [isOpen, setIsOpen] = useState(false);
    const [loader, setLoader] = useState(false);
    const [isLoadingCustom, setLoadingCustom] = useState(true)
    const [scrollY, setScrollY] = useState<any>('')
    const dispatch = useAppDispatch();
    const ref = useRef(null);

    const handleClickOutside = (event:any) => {
        // @ts-ignore
        if (ref.current && !ref.current?.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    useEffect(()=>{
        closePlayer();
        document.body.classList.add('orange-player')
        document.body.classList.remove('scroll-down')
        changeColorBackgroundMeta('#1E1E1E');
    }, [])

    useEffect(() => {
        const makeAsync = async () => {
            await dispatch(getCatalog(true));
            setLoader(true)
        };
        makeAsync();
    }, [dispatch]);

    useEffect(()=>{
        if(!isLoading){
            setTimeout(()=>{
                setLoadingCustom(false)
            },1000)
        }
    }, [isLoading])

    useEffect(()=>{
        if(isOpen){
            document.body.style.overflow = 'hidden'
        }
        else{
            document.body.style.overflow = 'unset'
        }
    }, [isOpen])

    const popupOpenHandler = (track:any) => {
        if(document.querySelectorAll('.ochre-js-pause')[0]) {
            let clickEvent = new MouseEvent("click", {
                "view": window,
                "bubbles": true,
                "cancelable": true
            });
            document.querySelectorAll('.ochre-js-pause')[0].dispatchEvent(clickEvent);
        }
        dispatch(getReleaseDescription(track.id));
        setIsOpen(true)
    }
    const popupCloseHandler = () => {
        setIsOpen(false)
    }

    const playerHandler = async (id:string) => {
        const token = await getToken();
        fetch(GET_MEDIAS + id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ token
            },
        }).then((response) => response.json())
          .then(json => {
              player.play(json.results)
          })
    }

    const preventDefault = (e:any) => {
        e.preventDefault();
    }

    const syncHeight = () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty(
          '--window-inner-height',
          `${vh}px`
        );
        window.setTimeout(() => {
            document.documentElement.style.setProperty(
              '--window-inner-height',
              `${vh}px`
            );
        }, 1000);
    }

    useEffect(()=>{
        window.addEventListener('resize', syncHeight);
    })

    useEffect(()=>{
        window.addEventListener('load', syncHeight);
    }, [])

    useEffect(()=>{
        const modal = document.querySelector('.popup')!
        if(isOpen){
            if(window.scrollY && window.scrollY !== 0) {
                setScrollY(window.scrollY);
            }
            document.documentElement.classList.add('fix-scroll');
            modal.addEventListener('pointermove', preventDefault);
        }
        else{
            document.documentElement.classList.remove('fix-scroll');
            modal.removeEventListener('pointermove', preventDefault);
            window.scrollTo(0, scrollY);
        }
    },[isOpen, scrollY])

    useEffect(()=>{
        setScrollY(window.scrollY - 1);
    }, [])

    const trackItems = tracks.map((track:any) =>
        <Track key={track.id} track={track} isLoading={isLoading} open={()=>popupOpenHandler(track)} listen={()=>playerHandler(track.id)}/>
    );

    const PopupInfo = () => (
        <div className="popup-content" ref={ref}>
            <button onClick={popupCloseHandler} className="popup-close">Close</button>
            <div className="fix"/>
            <div className="fix"/>
            <div className="popup-content-scroll">
                <div className="popup-top-info">
                    <h5 className="h5">Artist: {singleTrack.artist.name}</h5>
                    <p className="p">Album Title: {singleTrack.title}</p>
                    <p className="p">Number: {singleTrack.catNumber}</p>
                </div>
                <img src={singleTrack.imageUrl} alt="" className="popup-image"/>
                <p className="description">{singleTrack.description}</p>
                <div className="line"/>
                <div className="list">
                    <ul>
                        {list.map((listItem:any) =>
                            <li key={listItem.id}>
                                {listItem.title}
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    )

    return (
        <main>
            <div className={isLoadingCustom ? 'custom-loader' : 'custom-loader hide'}>
                <p>loading...</p>
            </div>
            <section className="catalog">
                <Header/>
                {!isLoading && tracks.length > 0 && loader ?
                    <div className="tracks">
                        {trackItems}
                    </div>
                    :
                    null
                }
                <div className={isOpen ? "popup opened" : "popup"}>
                    {!isSingleLoading && singleTrack.artist?
                        <PopupInfo/>
                        :
                        null
                    }
                </div>
            </section>
            <Footer transparent/>
        </main>
    )
}