import React, {useEffect, useState} from "react";
import './style.css';
import {getPageOptions} from "../../store/actions/project";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {Link} from "react-router-dom";
import CookieConsent from "react-cookie-consent";

export const Footer = (props:any) => {
  const [newsletterOpen, setNewsletterOpen] = useState(false);
  const [color, setColor] = useState('#000');
  const [email, setEmail] = useState('');
  const [isSuccessSent, setSuccessSend] = useState(false);
  const [isErrorSent, setErrorSend] = useState(false);
  const dispatch = useAppDispatch();
  const options = useAppSelector((state) => state.project.options);

  useEffect(()=>{
    const makeAsync = async () => {
      await dispatch(getPageOptions());
    };
    makeAsync();
  }, [dispatch])

  useEffect(() => {
    const checkURLParams = () => {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get('newsletter') === 'open') {
        setNewsletterOpen(true);
      }
    };
    checkURLParams();

    window.addEventListener('popstate', checkURLParams);

    return () => {
      window.removeEventListener('popstate', checkURLParams);
    };
  }, []);

  const openNewsletterPopup = () => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('newsletter', 'open');
    const newUrl = window.location.pathname + '?' + urlParams.toString();
    window.history.pushState({path: newUrl}, '', newUrl);

    setNewsletterOpen(true);
  }

  const closeNewsletterPopup = () => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete('newsletter');
    const newUrl = window.location.pathname + '?' + urlParams.toString();
    window.history.pushState({path: newUrl}, '', newUrl);

    setNewsletterOpen(false);
  }

  useEffect(()=>{
    if(document.querySelectorAll('.mailchimp input')[0]) {
      // @ts-ignore
      document.querySelectorAll('.mailchimp input')[0].placeholder = 'Enter Your E-Mail Address';
    }
  },[options])

  // eslint-disable-next-line
  useEffect(()=>{
    setColor(document.body.style.backgroundColor)
  })

  const validateEmail = (email: string) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const newsletterHandler = (e:any) => {
    e.preventDefault();
    if (validateEmail(email) && email.trim() !== '') {
      fetch('https://connect.mailerlite.com/api/subscribers', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiNDk2NmZkMjFkNjczZWNmMWEyOThkMWRhZGJiZWQ5MTAxMzVlNWZiY2E0YjFjNGE2OGY2OWE0MWFhYWI2Y2ZkNTEzYmMyZTE5YjQ2NDRkYzgiLCJpYXQiOjE3MTQ0Nzg3MTIuNjQzNzc5LCJuYmYiOjE3MTQ0Nzg3MTIuNjQzNzgyLCJleHAiOjQ4NzAxNTIzMTIuNjM5ODU1LCJzdWIiOiI5MzE4NjEiLCJzY29wZXMiOltdfQ.GyNbbQkTrQLh-4QsrK7KUsw2qVlMwNJQtT1gQjKhaB4E_Amrgxudx2bqR-GOdB11Rncc9-3nGg9YXB02q60WSUOXw9SuNxFABhCN4Pdg4r_bX7-DX3bh2p53LStWrUe7_Eo37vennRyLFthC44xHmr5pD1NcsHzMhaV2qv9PkAA1Y94OXZcSNpWfx4D81KuCbCEy1h72RjDeej3Ab3dqZPGcS_dQNbZ1cbeGHRi2hNelRJwULKcBG6M8_R00bU-06O2H9SM-ofYDMpOMcSoHnfm_hdbiKAqOCxjOLpq4aCrGM_l1QfI-Jx3k-RScKPg-m75w2C7K3XvW1usflca51bdYqmlrRkWD65Far9c8QHXCTm6nkIHqeYgMuDkkoi2Hnfqtb-bRp_5_ZeY1eYRK0iQRyvPUO2gdQKK2I1nPC47pYFo4A0VZ-TcaRBV6iLo86-KM6BL6w9lPfiHoTXgw1DhEJreOWp5P0i2SNii4OwioUoa5lwutSgla5aL21TkTBDx_fDrz_bT3MxdA6qz8cQBIkPyt2-FQzQLuQ8fZLx2gVvucKmV3RHH8swGwZC_xPZjniQqVZzg1c9-ypkaLIoWew03Uyev5vw3l0FZA87SFZiKDwRijjfvrh9svFBckVMFC-fV6_1mAEHXMGBQYQtD6bz_sgP7Mbx1yEFKXrPU'
        },
        body: JSON.stringify({
          email: email,
        }),
      }).then((response) => {
        if (response.ok) {
          setSuccessSend(true);
          setEmail('')
        } else {
          setErrorSend(true)
        }
      })
    } else {
      console.log('Invalid email');
    }
  }

  return (
    <>
      <div className="footer" style={props.style}>
        <div className="footer-menu">
          {options && options.acf &&
          <div className='footer-contact p gray' dangerouslySetInnerHTML={{__html: options.acf.contact_text}}/>
          }
          <div className='footer-links'>
            {options && options.acf && options.acf.social &&
              options.acf.social.map((item:any, index: number) => (
                <button onClick={()=>window.open(`${item.link.url}`, '_blank')} className='p gray' key={index}>{item.link.title}</button>
              ))}
            <button onClick={openNewsletterPopup} className='p gray'>Newsletter</button>
          </div>
          <div className='footer-links'>
            <Link to={'/impressum'} className='p gray'>Impressum</Link>
            <Link to={'/privacy'} className='p gray'>Privacy</Link>
            <p className='p gray'>{`©planisphere ${new Date().getFullYear()}`}</p>
          </div>
        </div>
        {/*<div className='footer-bottom'>*/}
        {/*  <p className="p copyright">{`©planisphere ${new Date().getFullYear()}`}</p>*/}
        {/*  <div className='footer-made'>*/}
        {/*    <p className='p'>Design: <a href="/" className='gray'>Anne Büttner</a> - <a href="/" className='gray'>Jonathan Castro Alejos</a></p>*/}
        {/*    <p className='p'>Code: <a href="https://mindnow.io" target={'_blank'} rel="noreferrer" className='gray'>Mindnow</a></p>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
      <CookieConsent
        location="bottom"
        buttonText="CLOSE"
        cookieName="cookieName"
        style={{backgroundColor: color}}
        containerClasses={'cookie-wrapper'}
        contentClasses={'cookie-text'}
        buttonClasses={'cookie-button'}
        expires={150}
        // debug={true}
      >
        This site uses cookies and similar Technologies. For Information, please read our Cookies Policy.
      </CookieConsent>
      <div className={newsletterOpen ? "popup opened" : "popup"}>
        <button onClick={closeNewsletterPopup} className="p orange close">close</button>
        <div className="popup-contact mailchimp">
          {/*{!isLoading && Object.keys(options).length !== 0 &&*/}
          {/*<MailchimpSubscribe url={options.acf.mailchimp_link}/>*/}
          {/*}*/}
          <div>
            <input type="email" placeholder={'Enter Your E-Mail Address'} onChange={(event)=>setEmail(event.currentTarget.value)} value={email}/>
            <button onClick={newsletterHandler}>Submit</button>
          </div>
          {isSuccessSent && <p>Thank you for subscribing!</p>}
          {isErrorSent && <p style={{color: 'red'}}>Error occurred while subscribing!</p>}
        </div>
      </div>
    </>
  )
}