import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export default function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
    if(document.querySelector('.ochre-cart-container') && document.querySelector<HTMLElement>('.ochre-cart')) {
      document.querySelector('.ochre-cart-container')!.classList.remove('shown')
      document.querySelector<HTMLElement>('.ochre-cart')!.style.display = 'none'
    }
  }, [pathname])

  useEffect(()=>{
    window.addEventListener("click", handleClick);
    return () => {
      window.removeEventListener("click", handleClick);
    };
  })

  const handleClick = () => {
    if(document.querySelectorAll('.ochre-cart')[0]){
      // @ts-ignore
      if(document.querySelectorAll('.ochre-cart')[0].style.display === 'table'){
        document.querySelectorAll('.ochre-cart-container')[0].classList.add('shown')
      }
      else{
        document.querySelectorAll('.ochre-cart-container')[0].classList.remove('shown')
      }
    }
  }

  return null
}