import React from "react";
import {Header} from "../../components/Header";
import {Footer} from "../../components/Footer";
import './style.css';
import back from '../../../assets/images/football.png';
import release from "../../../assets/images/Bildschirmfoto 2021-04-19 um 00.03 1.jpg";
import visualizer from "../../../assets/images/Bildschirmfoto 2020-12-16 um 20.59 1.jpg";

export const ProductionScreen = () => {

    return (
        <main>
            <section className="production">
                <Header/>
                <div className="container">
                    <div className="production-top">
                        <img src={back} alt=""/>
                        <h2 className="h2 orange">Creative <br/>Production</h2>
                    </div>
                </div>
                <section className="release">
                    <div className="release-text">
                        <p>Planisphere’s determination to deliver high quality performance in creative direction, project management, with a profound understanding of intersections and transdisciplinarity, makes the agency a strong sparring partner.</p>
                        <p>With their network-based agency structure they are used to working with a high sense of flexibility and proactivity.</p>
                        <p>Through evaluation of a clients resources, capacity and existing competencies, we can formulate bespoke and budget-effective structures to execute any project. Planisphere offers tailor-made brand activation and digital transformation projects that recontextualise a client's brand identity or products through cultural programming.</p>
                    </div>
                    <div className="release-block">
                        <div className="release-block-top">
                            <h5 className="h5">Teaser PP x Surface</h5>
                        </div>
                        <div className="release-block-content">
                            <img src={release} alt=""/>
                        </div>
                    </div>
                </section>
                <section className="visualizer">
                    <div className="visualizer-top">
                        <h5 className="h5 dark">The Visualiser</h5>
                    </div>
                    <div className="visualizer-content">
                        <img src={visualizer} alt=""/>
                    </div>
                </section>
            </section>
            <Footer transparent={true}/>
        </main>
    )
}