import {AUTH_HOST} from "../../utils/constants";

export const getToken = async () => {
    const res = await fetch(AUTH_HOST, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    const body = await res.json();
    return body.token;
}
