import {
    GET_ARTISTS_ERROR,
    GET_ARTISTS_LOADING,
    GET_ARTISTS_SUCCESS, GET_CONTENT_ARTIST_SUCCESS,
    GET_SINGLE_ARTIST_ERROR,
    GET_SINGLE_ARTIST_LOADING,
    GET_SINGLE_ARTIST_SUCCESS
} from "../../utils/types";

const initialState = {
    artists: [],
    videos: [],
    single: [],
    isLoading: false,
    isLoadingSingle: false,
    isError: false,
    isErrorSingle: false,
}

interface Action {
    type: string;
    payload?: any
}

export const artistReducer = (state = initialState, action:Action) => {
    switch (action.type) {
        case GET_ARTISTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                artists: action.payload,
                isError: false
            }
        case GET_ARTISTS_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
        case GET_ARTISTS_LOADING:
            return {
                ...state,
                isLoading: true,
                isError: false
            }
        case GET_SINGLE_ARTIST_SUCCESS:
            return {
                ...state,
                isLoadingSingle: false,
                single: action.payload,
                isErrorSingle: false
            }
        case GET_SINGLE_ARTIST_ERROR:
            return {
                ...state,
                isLoadingSingle: false,
                isErrorSingle: true,
            }
        case GET_SINGLE_ARTIST_LOADING:
            return {
                ...state,
                isLoadingSingle: true,
                isErrorSingle: false
            }
        case GET_CONTENT_ARTIST_SUCCESS:
            return {
                ...state,
                videos: action.payload,
            }
        default:
            return state
    }
}