import React, {useEffect, useState} from "react";
import {Header} from "../../components/Header";
import './style.css';
import {Footer} from "../../components/Footer";
import {getPageInfo} from "../../store/actions/project";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {ListBlock} from "../../components/ACF/list-block";
import {ImageBlock} from "../../components/ACF/image-block";
import {VideoBlock} from "../../components/ACF/video-block";
import {changeColorBackgroundMeta, closePlayer} from "../../utils/helpers";
import {Helmet} from "react-helmet";

export const AboutScreen = () => {

  const dispatch = useAppDispatch();
  const page = useAppSelector((state) => state.project.page);
  const isLoading = useAppSelector((state) => state.project.isLoadingPage);
  const [isLoadingCustom, setLoadingCustom] = useState(true)

  useEffect(()=>{
    document.body.classList.add('orange-player')
    document.body.classList.remove('scroll-down')
    changeColorBackgroundMeta('#1E1E1E');
    closePlayer();
  }, []);

  useEffect(()=>{
    const makeAsync = async () => {
      await dispatch(getPageInfo(140));
    };
    makeAsync();
  }, [dispatch])

  useEffect(()=>{
    if(!isLoading){
      setTimeout(()=>{
        setLoadingCustom(false)
      },500)
    }
  }, [isLoading])


  return (
    <main>
      {!isLoading && Object.keys(page).length !== 0 && page.id === 140 &&
          <Helmet>
              <title>{page.acf.meta_title}</title>
              <meta name="description" content={page.acf.meta_description} />
              <meta property="og:image" content={page.acf.meta_image} />
              <meta property="og:title" content={page.acf.meta_title} />
              <meta property="og:description" content={page.acf.meta_description} />
          </Helmet>
      }
      <div className={isLoadingCustom ? 'custom-loader' : 'custom-loader hide'}>
        <p>loading...</p>
      </div>
      <section className="about">
        <Header/>
        {!isLoading && Object.keys(page).length !== 0 && page.id === 140 ?
          <div className="about-wrapper">
            {page.acf.content.map((layout:any, index: number) => (
              layout.acf_fc_layout === 'text_block' ?
                <div className={`about-text ${layout.width === 'large' ? 'large' : ''}`} key={index}>
                  {layout.layout === 'right' ?
                    <div className="left-side" dangerouslySetInnerHTML={{__html: layout.content}}/>
                    :
                    <>
                      <div className="left-side">
                        <p className="h5" dangerouslySetInnerHTML={{__html: layout.title}}/>
                      </div>
                      <div className="right-side" dangerouslySetInnerHTML={{ __html: layout.content }}/>
                    </>
                  }
                </div>
                : layout.acf_fc_layout === 'list_block' ?
                <ListBlock layout={layout} key={index} isClickable={true}/>
                : layout.acf_fc_layout === 'image_block' ?
                <ImageBlock layout={layout} key={index}/>
                  : layout.acf_fc_layout === 'video_block' ?
                    <VideoBlock layout={layout} key={index}/>
                    :
                    <></>
            ))}
          </div>
          :
          <></>
        }
      </section>
      <Footer transparent={true}/>
    </main>
  )
}