import React, {useEffect} from "react";
import {Header} from "../../components/Header";
import './style.css';
import back from '../../../assets/images/placeholder.jpg';
import {Footer} from "../../components/Footer";

export const ArchitectureScreen = () => {

    useEffect(()=>{
        if(document.querySelectorAll('.ochre-transport-wrapper.ochre-player-footer')[0])
            document.querySelectorAll('.ochre-transport-wrapper.ochre-player-footer')[0].classList.remove('orange-bg')
    }, [])

    return (
        <main>
            <section className="architecture">
                <Header/>
                <div className="container">
                    <div className="architecture-top">
                        <img src={back} alt=""/>
                        <h2 className="h2 orange">Cultural <br/>Architecture</h2>
                        <p className="p">Strategy to Action</p>
                    </div>
                    <div className="architecture-text">
                        <p>Planisphere's core aims are to create a cultural and creative eco-system through which organizations and individuals can achieve their goals through holistic strategies and collaborative intrapreneurship.</p>
                        <p>As cultural architects, we create a flat work environment by modeling and reinforcing equality, respect and high performance.</p>
                        <h5 className="h5">Inclusivity</h5>
                        <p>As cultural architects, we affirm and protect the identity and physical and psychological safety of others.</p>
                        <h5 className="h5">Micro-Coaching</h5>
                        <p>If we need rapid iterations on products, we need rapid iterations on people. The shift to micro-coaching results in people being coached in frequent, short, focused coaching conversations that accompany their work.</p>
                        <h5 className="h5">Agile learning</h5>
                        <p>An agile mindset is the crucible of disruptive innovation. Learning agility is the process of learning at or above the speed of change - both individually and organizationally. We explore and experiment playfully, develop new areas of action on our own initiative, and share our findings.</p>
                        <h5 className="h5">Sense of responsibility</h5>
                        <p>We live cultural responsibility. We hold ourselves accountable not only for our results, but also for how we achieve those results.  We model inclusion, conduct micro-coaching, and demonstrate learning flexibility. By continually reinforcing this exemplary behavior, we gain the credibility to hold others accountable in this regard.</p>
                    </div>
                </div>
            </section>
            <Footer transparent={true}/>
        </main>
    )
}