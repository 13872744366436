import {Dispatch} from "redux";
import {
    GET_RELEASE_INFO_ERROR,
    GET_RELEASE_INFO_LOADING,
    GET_RELEASE_INFO_SUCCESS,
    GET_RELEASES_ERROR,
    GET_RELEASES_LOADING,
    GET_RELEASES_SUCCESS,
    GET_SINGLE_RELEASE_ERROR,
    GET_SINGLE_RELEASE_LOADING,
    GET_SINGLE_RELEASE_SUCCESS,
    GET_TRACKS_ARTIST_ERROR,
    GET_TRACKS_ARTIST_LOADING,
    GET_TRACKS_ARTIST_SUCCESS, GET_TRACKS_FORMAT_ERROR, GET_TRACKS_FORMAT_LOADING, GET_TRACKS_FORMAT_SUCCESS,
    GET_TRACKS_RELEASE_ERROR,
    GET_TRACKS_RELEASE_LOADING,
    GET_TRACKS_RELEASE_SUCCESS,
    PLAYER_SUCCESS
} from "../../utils/types";
import {
    GET_ADDITIONAL_IMAGES, GET_ADDITIONAL_IMAGES_FOR_RELEASE, GET_ADDITIONAL_VIDEOS,
    GET_ARTISTS,
    GET_CATALOG, GET_CATALOG_ALL_PRODUCTS,
    GET_EXTERNAL_LINKS, GET_PHYSICAL_PRODUCT,
    GET_PRODUCT,
    GET_TRACK_PRODUCT
} from "../../utils/apis";
import {getToken} from "./auth";

export const getCatalog = (onlyTracks?: boolean) => async (dispatch: Dispatch) => {
    try {
        dispatch({
            type: GET_RELEASES_LOADING
        });
        const token = await getToken();

        fetch(onlyTracks ? GET_CATALOG : GET_CATALOG_ALL_PRODUCTS, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => response.json())
          .then(json => {
              dispatch({
                  type: GET_RELEASES_SUCCESS,
                  payload: json.results
              });
          })
    } catch (e) {
        dispatch({
            type: GET_RELEASES_ERROR,
        });
    }
}

export const setPlayer = (playerObject: any) => async (dispatch: Dispatch) => {
    dispatch({
        type: PLAYER_SUCCESS,
        payload: playerObject
    });
}

export const getReleaseDescription = (id:string) => async (dispatch: Dispatch) => {
    try {
        dispatch({
            type: GET_SINGLE_RELEASE_LOADING
        });
        const token = await getToken();
        fetch(GET_CATALOG + id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ token
            },
        }).then((response) => response.json())
            .then(json => {
                fetch(GET_CATALOG + id + '/tracks', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                }).then((response) => response.json())
                    .then(tracks => {
                        dispatch({
                            type: GET_SINGLE_RELEASE_SUCCESS,
                            payload: {'description': json, 'tracks':tracks.results}
                        });
                    })

            })
    } catch (e) {
        dispatch({
            type: GET_SINGLE_RELEASE_ERROR,
        });
    }
}

export const getTracksByArtist = (id:string) => async (dispatch: Dispatch) => {
    try {
        dispatch({
            type: GET_TRACKS_ARTIST_LOADING
        });
        const token = await getToken();
        fetch(GET_ARTISTS + id + '/releases', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ token
            },
        }).then((response) => response.json())
          .then(json => {
              dispatch({
                  type: GET_TRACKS_ARTIST_SUCCESS,
                  payload: json.results
              });
          })
    } catch (e) {
        dispatch({
            type: GET_TRACKS_ARTIST_ERROR,
        });
    }
}

export const getReleaseInfo = (id:string) => async (dispatch: Dispatch) => {
    try {
        dispatch({
            type: GET_RELEASE_INFO_LOADING
        });
        const token = await getToken();
        fetch(GET_CATALOG + id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ token
            },
        }).then((response) => response.json())
          .then(json => {
              if(json.code && json.code === 404) {
                  fetch(GET_PHYSICAL_PRODUCT + id, {
                      method: 'GET',
                      headers: {
                          'Content-Type': 'application/json',
                          'Authorization': 'Bearer ' + token
                      },
                  }).then((response) => response.json())
                    .then(json => {
                        fetch(GET_ADDITIONAL_IMAGES_FOR_RELEASE + id, {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + token
                            },
                        }).then((response) => response.json())
                          .then(images => {
                              dispatch({
                                  type: GET_RELEASE_INFO_SUCCESS,
                                  payload: {
                                      'track': json.results[0],
                                      'product': json.results,
                                      // 'links': links.results,
                                      'images': images.results,
                                  }
                              });
                          });
                    })
              }
              else {
                  fetch(GET_PRODUCT + id, {
                      method: 'GET',
                      headers: {
                          'Content-Type': 'application/json',
                          'Authorization': 'Bearer ' + token
                      },
                  }).then((response) => response.json())
                    .then(product => {
                        const productId = product.results.find((item: any) => item.format === 'Vinyl')
                        let ids;
                        if (productId) {
                            ids = productId.id
                        } else {
                            ids = '1234'
                        }
                        fetch(GET_ADDITIONAL_IMAGES + ids, {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + token
                            },
                        }).then((response) => response.json())
                          .then(images => {
                              fetch(GET_ADDITIONAL_VIDEOS + id, {
                                  method: 'GET',
                                  headers: {
                                      'Content-Type': 'application/json',
                                      'Authorization': 'Bearer ' + token
                                  },
                              }).then((response) => response.json())
                                .then(videos => {
                                  fetch(GET_EXTERNAL_LINKS + id, {
                                      method: 'GET',
                                      headers: {
                                          'Content-Type': 'application/json',
                                          'Authorization': 'Bearer ' + token
                                      },
                                  }).then((response) => response.json())
                                    .then(links => {
                                        dispatch({
                                            type: GET_RELEASE_INFO_SUCCESS,
                                            payload: {
                                                'track': json,
                                                'product': product.results,
                                                'links': links.results,
                                                'images': images.results,
                                                'videos': videos.results
                                            }
                                        });
                                    })
                              })
                          })
                    })
              }
          })
    } catch (e) {
        dispatch({
            type: GET_RELEASE_INFO_ERROR
        });
    }
}

export const getTracksByRelease = (id:string) => async (dispatch: Dispatch) => {
    try {
        dispatch({
            type: GET_TRACKS_RELEASE_LOADING
        });
        const token = await getToken();
        fetch(GET_CATALOG + id + '/tracks', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ token
            },
        }).then((response) => response.json())
          .then(json => {
              fetch(GET_ADDITIONAL_IMAGES + id, {
                  method: 'GET',
                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer ' + token
                  },
              }).then((response) => response.json())
                .then(images => {
                    dispatch({
                        type: GET_TRACKS_RELEASE_SUCCESS,
                        payload: json.results
                    });
                })
          })
    } catch (e) {
        dispatch({
            type: GET_TRACKS_RELEASE_ERROR
        });
    }
}

export const getTracksFormat = (id:string) => async (dispatch: Dispatch) => {
    try {
        dispatch({
            type: GET_TRACKS_FORMAT_LOADING
        });
        const token = await getToken();
        fetch(GET_TRACK_PRODUCT + id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ token
            },
        }).then((response) => response.json())
          .then(json => {
              dispatch({
                  type: GET_TRACKS_FORMAT_SUCCESS,
                  payload: json.results
              });
          })
    } catch (e) {
        dispatch({
            type: GET_TRACKS_FORMAT_ERROR
        });
    }
}