import React, {useEffect, useState} from "react";
import {Header} from "../../components/Header";
import './style.css';
import {Footer} from "../../components/Footer";
import {getPageInfo} from "../../store/actions/project";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {ImageBlock} from "../../components/ACF/image-block";
import {ListBlock} from "../../components/ACF/list-block";
import {VideoBlock} from "../../components/ACF/video-block";
import {changeColorBackgroundMeta, closePlayer} from "../../utils/helpers";
import {VideoFile} from "../../components/ACF/video-file";
import {SpotifyBlock} from "../../components/ACF/spotify-block";
import {Helmet} from "react-helmet";

export const PublishingScreen = () => {

    const dispatch = useAppDispatch();
    const page = useAppSelector((state) => state.project.page);
    const isLoading = useAppSelector((state) => state.project.isLoadingPage);
    const [isLoadingCustom, setLoadingCustom] = useState(true)

    useEffect(()=>{
        const makeAsync = async () => {
            await dispatch(getPageInfo(158));
        };
        makeAsync();
    }, [dispatch])

    useEffect(()=>{
        closePlayer();
        document.body.classList.add('orange-player')
        document.body.classList.remove('scroll-down')
        changeColorBackgroundMeta('#1E1E1E');
    }, [])

    useEffect(()=>{
        if(!isLoading){
            setTimeout(()=>{
                setLoadingCustom(false)
            },500)
        }
    }, [isLoading])


    return (
        <main>
            {!isLoading && Object.keys(page).length !== 0 && page.id === 158 &&
              <Helmet>
                  <title>{page.acf.meta_title}</title>
                  <meta name="description" content={page.acf.meta_description} />
                  <meta property="og:image" content={page.acf.meta_image} />
                  <meta property="og:title" content={page.acf.meta_title} />
                  <meta property="og:description" content={page.acf.meta_description} />
              </Helmet>
            }
            <div className={isLoadingCustom ? 'custom-loader' : 'custom-loader hide'}>
                <p>loading...</p>
            </div>
            <section className="publishing">
                <Header/>
                {!isLoading && Object.keys(page).length !== 0 && page.id === 158 ?
                  <div className="container">
                      <div className="publishing-top">
                          {page.acf.content[0].hero_is_video ?
                            page.acf.content[0].video_is_iframe ?
                              <div className="iframe-container">
                                  <iframe
                                    src={page.acf.content[0].iframe_link}
                                    frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"
                                    className="iframe"
                                    title="Planisphere_Showreel_Dec2018_V2"
                                  />
                              </div>
                              :
                              <VideoFile link={page.acf.content[0].video.url} withButtons={false}/>
                            :
                            <img src={page.acf.content[0].image.url} alt=""/>
                          }
                          <h2 className="h2 orange">{!page.acf.content[0].title_is_the_same_as_page_title ? page.acf.content[0].hero_title : page.title.rendered}</h2>
                      </div>
                      {page.acf.content.map((layout:any, index: number) => (
                        layout.acf_fc_layout === 'text_block' ?
                          <div className={`publishing-text ${layout.width === 'large' ? 'large' : ''}`} key={index}>
                              {layout.layout === 'right' ?
                                <div className="left-side" dangerouslySetInnerHTML={{__html: layout.content}}/>
                                :
                                <>
                                    <div className="left-side">
                                        <p className="h5" dangerouslySetInnerHTML={{__html: layout.title}}/>
                                    </div>
                                    <div className="right-side" dangerouslySetInnerHTML={{ __html: layout.content }}/>
                                </>
                              }
                          </div>
                          : layout.acf_fc_layout === 'video_block' ?
                          <VideoBlock layout={layout} key={index}/>
                          : layout.acf_fc_layout === 'list_block' ?
                            <ListBlock layout={layout} key={index} isClickable={true}/>
                            : layout.acf_fc_layout === 'image_block' ?
                              <ImageBlock layout={layout} key={index}/>
                              : layout.acf_fc_layout === 'spotify' ?
                                <SpotifyBlock layout={layout} key={index}/>
                          :
                          <></>
                      ))}
                  </div>
                  :
                  <></>
                }
            </section>
            <Footer transparent={true}/>
        </main>
    )
}