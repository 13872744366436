import React from "react";

export const ImageBlock = ({layout}:{layout:any}) => {
  return (
    <div className={"single-project-image-block"}>
      {layout.two_images_in_the_row ?
        <div>
          {layout.images.map((image:any, i: number) => (
            <img src={image.image.url} alt="" key={i}/>
          ))}
        </div>
        :
        <img src={layout.image.url} alt="" className={layout.position}/>
      }
    </div>
  )
}